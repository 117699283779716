.itemFriends {
  display: block;
  width: fit-content;
  cursor: pointer;
}


.itemFriendsContainer {
  display: block;
  height: 60px;
  width: 55px;
  float: left;
  background-color: #fff0c9;
  box-shadow: 3px 3px 0 #a59655;
  border-radius: 10%;
  margin-inline-end: 4px;
  margin-inline-start: 4px;
  position: relative;
}

.itemFriendsContainer:dir(rtl) {
  float: right;

}

.itemFriendsContainer .indicateurPremium {
  display: block;
  position: absolute;
  top: 30px;
  inset-inline-end: -10px;
  height: 30px;
  width: 30px;
  z-index: 3;
}


.itemFriends .profilContainer {
  display: block;

  height: 70%;
  width: 90%;
  background: linear-gradient(#000000 50%, #343434) 100% 100% no-repeat;
  border-radius: 7px;
  overflow: hidden;
  position: relative;
  margin: 3px;
  margin-bottom: 1px;
}

.itemFriends .refrac {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  background: center/cover no-repeat url("/public/assets/interfaces/refractionEffect.png");
}

.itemFriends .image {
  display: block;
  height: 50px;
  width: 50px;
  margin-top: -4px;
}

.itemFriends .name {
  display: block;
  width: 100%;
  height: 15px;
  font-weight: 700;
  color: #927d3e;
  text-align: center;
  font-size: 11px;
  overflow: hidden;
}

.itemFriends .online {
  display: block;
  width: 7px;
  height: 7px;
  border-radius: 10px;
  background-color: #a49453;
  position: absolute;
  top: 0;
  inset-inline-end: 0;
  border: solid 1px #bfae81;
  border-bottom: solid 1px #e7e5de;
}

.itemFriends .online.on {
  background-color: #15ff00;
  box-shadow: 0 0 5px 3px #15ff00;
}

.itemFriends .online.off {
  background-color: #a49453;
}