.multi {
    display: block;
}

.multi .layer {
    width: 100%;
    height: 230px;
    margin-bottom: 5px;
}

.multi .sousTitre {
    font-size: 12px;
    text-align: center;
}

.multi .bouton {
    width: 200px;
    margin-right: 10px;
    float: left;
}

.multi .contaninders {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 170px;
    margin-top: 10px;
}


.multi .inputLink {
    width: 95px;
    height: 24px;
    float: left;
    margin: 0;
    margin-right: -10px;
    margin-top: 4px;
    margin-bottom: 5px;
    margin-bottom: 15px;

}

.multi .itemRoomContainer {
    width: 570px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -10px;
}

.multi .itemRoomContainer .name {
    width: 170px;
}