.reset {
    width: 370px;
    min-height: 300px;
}


.reset .layer {
    width: 100%;
    height: 220px;
    margin-bottom: 5px;
    margin-top: 5px;

}

.reset .titre {
    width: 76%;
}

.reset .sousTitre {
    font-size: 12px;
    text-align: center;
    margin-bottom: 15px;
    margin-top: 10px;
}


.reset .bouton {
    margin-top: 15px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    clear: both;
}


.reset i.icon-ok {
    inset-inline-end: 35px;
    width: 1em;
    inset-inline-start: unset;
    color: rgb(0, 200, 0);

}

.reset i.icon-cancel,
.reset i.icon-notavailable {
    inset-inline-end: 35px;
    width: 1em;
    inset-inline-start: unset;
    color: rgb(200, 0, 0);
}

.reset i {
    display: inline;
    padding: 10px;
    color: #694523;
    text-align: center;
    position: absolute;
    inset-inline-start: 24px;
}

.reset input {
    text-indent: 30px;
}