.itemInvitation {
    display: block;

    width: 100%;
    height: 43px;
    padding: 0;
    margin: 0;
}


.itemInvitationContainer {
    display: block;

    width: 100%;
    height: 34px;
    background: linear-gradient(#FEF9E6, #FEEFC4);
    border-radius: 7px;

    border: solid 1px #E7D790;
    box-shadow: 0px 2px #A59655;
    padding: 0;

    float: none;

}


.itemInvitationContainer .imageContainer:dir(rtl),
.itemInvitationContainer .name:dir(rtl),
.itemInvitationContainer .Level:dir(rtl),
.itemInvitationContainer .Level .ico:dir(rtl),
.itemInvitationContainer .Level .ico:dir(rtl),
.itemInvitationContainer .Coin:dir(rtl),
.itemInvitationContainer .Coin .ico:dir(rtl) {

    float: right;


}

.itemInvitationContainer .flag:dir(rtl),
.huitemInvitationContainerd .name:dir(rtl) {
    float: right;
}


.itemInvitationContainer .flag {
    display: inline-block;
    height: 12px;
    width: auto;
    position: absolute;
    bottom: -1px;
    right: -2px;
    border-radius: 2px;
}


.itemInvitationContainer .imageContainer {
    width: 32px;
    height: 32px;
    margin-top: 1px;
    margin-inline-start: 5px;
    float: left;
    position: relative;
    overflow: hidden;
    border-radius: 6px;

}

.itemInvitationContainer .image {
    margin: 0;
    width: 32px;
    height: 32px;
}


.itemInvitationContainer .name {
    height: 34px;
    line-height: 2.7em;

    float: left;
    margin-inline-start: 5px;

    font-size: 14px;
    font-weight: 700;

    color: #694523;
    display: block;
    width: 100px;
}


.itemInvitation .desactivated {
    filter: saturate(0);
    pointer-events: none;
}

.itemInvitation .itemInvitationContainer .bouton.simple.small {
    position: relative;
    float: right;
    width: fit-content;
    width: 100px;
    min-width: 100px;
    margin: 4px;
}



.itemInvitationContainer .online {
    display: block;
    width: 7px;
    height: 7px;
    border-radius: 10px;
    background-color: #a49453;
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    border: solid 1px #bfae81;
    border-bottom: solid 1px #e7e5de;
}

.itemInvitationContainer .online.on {
    background-color: #15ff00;
    box-shadow: 0 0 5px 3px #15ff00;
}

.itemInvitationContainer .online.off {
    background-color: #a49453;
}