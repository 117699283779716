.pendinginvitation {
  position: absolute;
  width: 480px;
  min-height: 280px;

}

.pendinginvitation .perso {
  display: block;
  width: 240px;
  height: 120px;
  position: absolute;
  top: -100px;
  right: 50px;
  background: center/cover no-repeat url(/public/assets/interfaces/persoMouche.png);
}

.pendinginvitation .perso:dir(rtl) {
  transform: scaleX(-100%);
  float: left;

}

.pendinginvitation .layer {
  width: 100%;
  height: 200px;
  float: left;
}

.pendinginvitation .layer:dir(rtl) {
  float: left;
}




.pendinginvitation .fleches {
  width: fit-content;
  transform: rotate(-90deg) translate(50%, 0%);
  margin-left: auto;
  margin-right: auto;
}


.pendinginvitation .invitationScroll {
  display: block;
  width: 100%;
  height: 150px;
  overflow: hidden;
  scroll-behavior: smooth;
}