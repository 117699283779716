.alert {
    width: 350px;
    min-height: 350px;
    z-index: 20;
    transform: translateY(750);
    position: absolute;
}

.alert .titre{
    width: 75%;
    overflow: hidden;
    height: 40px;
}

.alert .layer {
    width: 100%;
}


