.home .gameMenuCounter {
    display: block;
    width: 530px;
    height: 210px;
    overflow: hidden;
    margin-right: auto;
    margin-left: auto;
    scroll-behavior: smooth;

}

.home .gameMenuContainer {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: fit-content;
    height: auto;
}

.home .gameMenu {
    display: block;
    width: 530px;
    height: 210px;
    overflow: hidden;
    margin-right: auto;
    margin-left: auto;
    clear: both;
    margin-bottom: 10px;
    margin-top: 10px;
    overflow: hidden;
    scroll-behavior: smooth;

}