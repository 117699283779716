.itemMode {
    display: block;

    width: 180px;
    height: 180px;

    /* border: solid 2px #fff;
    border-radius: 10%;
    background: linear-gradient(rgba(256, 256, 256, 0.8), rgba(256, 256, 256, 0.2));
    ;

    box-shadow: -4px 4px 0 rgba(0, 0, 0, 0.1);
     */
    overflow: hidden;
    margin: 0px;

    position: relative;
}

span.itemMode span.sousTitre,
span.itemMode span.bouton-bet,
span.itemMode span.bouton-reward {
    float: none;
}

.itemMode .desactivate {
    filter: saturate(0%);
    pointer-events: none;
}

.itemMode img {
    width: auto;
    height: 65px;
    margin-left: auto;
    margin-right: auto;
}

.itemMode .checkBox {
    width: 55px;
    pointer-events: none;
    margin-top: 5px;
}




.itemMode .bouton-bet,
.itemMode .bouton-reward {
    height: 23px;

    padding: 0;
    position: relative;

    text-align: center;
    text-indent: 10px;
    display: block;
    line-height: 1.5em;
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.4);
    color: #fff;
    text-decoration: none;
    font-size: 11pt;
    font-weight: bold;

    border-radius: 5px;


    width: 120px;
    background: linear-gradient(#5FC86C 30%, #62955D);

    border: 1px solid #426932;
    border-top: 1px solid #fff;
    box-shadow: -1px 2px 0px rgba(0, 0, 0, 0.5),
        inset 0px -2px 0 #426932;

    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    /* margin-top: 5px; */

    /* inset-inline-end: 50%;
    transform: translateX(-50%);
*/
}

.itemMode .bouton-bet {
    background: linear-gradient(#86ccbb 30%, #5a9f93);

    border: 1px solid #41645d;
    border-top: 1px solid #fff;
    box-shadow: -1px 2px 0px rgba(0, 0, 0, 0.5),
        inset 0px -2px 0 #41645d;
}

.itemMode .bouton-bet .iconCoin {
    display: block;
    position: absolute;
    top: -2px;
    inset-inline-start: -3px;
    width: 25px;
    height: 45px;
    background: center/cover no-repeat url(/public/assets/icons/Coin.png);
    z-index: 2;
}





.itemMode .bouton-reward {

    background: linear-gradient(#5FC86C 30%, #62955D);

    border: 1px solid #426932;
    border-top: 1px solid #fff;
    box-shadow: -1px 2px 0px rgba(0, 0, 0, 0.5),
        inset 0px -2px 0 #426932;
}

.itemMode .bouton-reward .iconBuck {
    display: block;
    position: absolute;
    top: -3px;
    inset-inline-start: -7px;
    width: 45px;
    height: 32px;
    background: center/cover no-repeat url(../../../public/assets/icons/pileOfMoney.png);
    z-index: 2;
}