.messenger {
    display: block;
    position: fixed;
    top: 120px;
    left: 10px;
    width: max-content;
    height: max-content;

    pointer-events: auto;
    margin: 0;
    z-index: 20;
}

.messenger .indicateurPremium {
    display: block;
    position: absolute;

    height: 30px;
    width: 30px;
    z-index: 3;
}


.messenger .container {
    display: block;
    float: left;
    height: 100px;
    width: 90px;
    background: linear-gradient(#fff8dc 50%, #fff5b9) 100% 100% no-repeat;

    overflow: hidden;
    position: relative;
    margin: 0;
    border-radius: 40px / 50px;

    box-shadow: #beb05f 4px 4px 0px,
        -3px 10px 0px rgba(0, 0, 0, 0.4);
}

.messenger .profilContainer {
    display: block;

    height: 90%;
    width: 90%;
    background: linear-gradient(#000000 50%, #343434) 100% 100% no-repeat;

    overflow: hidden;
    position: relative;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2px;
    border-radius: 40px / 50px;
}


.messenger .image {
    display: block;
    height: 100px;
    width: 100px;
    margin-inline-start: -10px;
}

.messenger .name {
    display: block;
    width: 100%;
    height: 15px;
    font-weight: 700;
    color: #927d3e;
    text-align: center;
    font-size: 11px;
    overflow: hidden;
}

.messenger .online {
    display: block;
    width: 7px;
    height: 7px;
    border-radius: 10px;
    background-color: #a49453;
    position: absolute;
    bottom: 0;
    inset-inline-end: 45%;
    border: solid 1px #bfae81;
    border-bottom: solid 1px #e7e5de;
}

.messenger .online.on {
    background-color: #15ff00;
    box-shadow: 0 0 5px 3px #15ff00;
}

.messenger .online.off {
    background-color: #a49453;
}

.messenger .cardige {
    height: auto;
    width: 170px;

    min-height: 50px;

    cursor: default;
    padding: 0;
    position: relative;


    text-align: center;
    text-indent: 0;
    display: block;
    line-height: 1.5em;
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.4);
    text-decoration: none;
    font-size: 12px;
    font-weight: 600;

    margin: 15px;
    margin-top: 10px;

    border-radius: 5px;

    float: left;

    color: #8C7D3F;
    text-shadow: -2px 2px 0px #FFF4D2;
    background: linear-gradient(#F3E8B5 20%, #DDC37F 80%);
    border: 1px solid #A59655;
    border-top: 1px solid #FCE8D0;
    box-shadow: -3px 10px 0px rgba(0, 0, 0, 0.3),
        inset 0px -2px 0 #A59655;

}


.messenger .titre {
    font-size: 12pt;
    position: absolute;
    top: -10px;
    width: 100%;
    line-height: 0.7em;
    text-align: center;
    z-index: 2;
}



.messenger .triangle {
    display: block;
    height: 0;
    width: 0;

    margin-bottom: -5px;


    border-inline-end: 5px solid transparent;
    border-inline-start: 5px solid transparent;
    border-top: 10px solid #DDC37F;
    transform: translateX(-5px);

    margin-left: auto;
    margin-right: auto;
    position: absolute;
    bottom: 10px;
    inset-inline-start: 0;
}

.messenger .triangle::before {
    display: block;
    position: absolute;

    margin-bottom: -8px;


    border-inline-end: 10px solid transparent;
    border-inline-start: 10px solid transparent;
    border-top: 20px solid #A59655;
}

.messenger .triangle:dir(rtl) {
    transform: translateX(5px);
}

.messenger .message {
    display: block;
    text-align: center;
    margin: 10px 0 0 0;
}

.messenger .bouton {
    min-width: unset;
    width: 100px;

    margin: 5px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
}

.messenger .bouton-icon {
    position: absolute;
    inset-inline-end: 0;
    bottom: 0;
    cursor: pointer;
}