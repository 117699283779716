
.logo{
    display: block;
    width: 220px;
    height: 220px;
    margin-left: auto;
    margin-right: auto;
    background: center / contain no-repeat url(/public/assets/interfaces/logo.png) ;
}

.entranceContainer{
    display: block;
    position: relative;
    

    width: 740px;
    height: 360px;
    background: center / contain no-repeat url(/public/assets/interfaces/entrance.png) ;
    pointer-events:none;
}



.boutonsIcons{
    display: block;
    width: 740px;
    position: absolute;
    top: 0;
    margin-left: auto;
    margin-right: auto;
    pointer-events:auto;

}
.boutons{
    display: block;
    width: 740px;
    padding-inline-start: 20px;
    position: absolute;
    bottom:0;
    margin-left: auto;
    margin-right: auto;
    pointer-events:auto;
}

.boutonsIcons .toogleLanguage{
    float: right;
    margin: 5px;
}

.boutonsIcons .toogleLanguage:dir(rtl){
    float: left;

}
.boutons .bouton {
    float: left;
    width: 222px;
    margin: 10px;
}

.boutons .bouton:dir(rtl) {
    float: right;

}
