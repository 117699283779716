.App {
  text-align: center;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'icon';
    src: url('../font/icon.svg?19321497#icon') format('svg');
  }
}
*/
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "icon";
  font-style: normal;
  font-weight: normal;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-inline-start: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-cancel:before {
  content: '\e801';
}

/* '' */
.icon-ok:before {
  content: '\e803';
}

/* '' */
.icon-cancel-circle:before {
  content: '\e804';
}

/* '' */
.icon-ok-circle:before {
  content: '\e805';
}

/* '' */
.icon-plus-circle:before {
  content: '\e806';
}

/* '' */
.icon-plus:before {
  content: '\e807';
}

/* '' */
.icon-minus:before {
  content: '\e808';
}

/* '' */
.icon-minus-circle:before {
  content: '\e809';
}

/* '' */
.icon-info:before {
  content: '\e80a';
}

/* '' */
.icon-user:before {
  content: '\e80b';
}

/* '' */
.icon-star:before {
  content: '\e80c';
}

/* '' */
.icon-heart:before {
  content: '\e80d';
}

/* '' */
.icon-chat:before {
  content: '\f03d';
}

/* '' */
.icon-users:before {
  content: '\f064';
}

/* '' */
.icon-facebook:before {
  content: '\f300';
}

/* '' */
.icon-twitter-bird:before {
  content: '\f303';
}

/* '' */
.icon-comment:before {
  content: '\f4ac';
}

/* '' */
.icon-search:before {
  content: '\f50d';
}

/* '' */






.icon-fermer:before {
  content: '\e801';
}

.icon-glisser:before {
  content: 'A';
}

.icon-son:before {
  content: 'P';
}

.icon-son-on:before {
  content: 'Q';
}

.icon-classement:before {
  content: 'C';
}

.icon-profil:before {
  content: 'D';
}

.icon-acheter:before {
  content: 'E';
}

.icon-recharger:before {
  content: 'F';
}

.icon-parametre:before {
  content: 'G';
}

.icon-aider:before {
  content: 'H';
}

.icon-accueil:before {
  content: 'I';
}

.icon-agrandir:before {
  content: 'J';
}

.icon-vue:before {
  content: 'K';
}

.icon-trier-par-genre:before {
  content: 'L';
}

.icon-trier-par-nombre:before {
  content: 'M';
}

.icon-hd:before {
  content: 'N';
}

.icon-sd:before {
  content: 'O';
}


.icon-reduire:before {
  content: 'R';
}

.icon-music-on:before {
  content: 'S';
}

.icon-music:before {
  content: 'T';
}

.icon-loop-on:before {
  content: 'U';
}

.icon-loop:before {
  content: 'W';
}

.icon-shuffle:before {
  content: 'V';
}


.icon-password:before {
  content: 'X';
}

.icon-email:before {
  content: 'Y';
}

.icon-user:before {
  content: 'Z';
}

.icon-edit:before {
  content: 'a';
}

.icon-shutdown:before {
  content: 'b';
}

.icon-logout:before {
  content: 'c';
}

.icon-notavailable:before {
  content: '\e804';
}

.icon-disc:before {
  content: 'B';
}

.icon-replay:before {
  content: 'U';
}

.icon-pause:before {
  content: 'e';
}

.icon-cam-on:before {
  content: 'f';
}

.icon-cam-off:before {
  content: 'g';
}

.icon-report:before {
  content: 'h';
}

.icon-archive:before {
  content: 'i';
}

.icon-log:before {
  content: 'j';
}


.icon-baton:before {
  content: 'k';
}

.icon-epee:before {
  content: 'l';
}

.icon-coupe:before {
  content: 'm';
}

.icon-denier:before {
  content: 'n';
}

.icon-menu:before {
  content: 'o';
}

.icon-menu2:before {
  content: 'p';
}

.circular {
  border-radius: 50% !important;
}

input {
  display: block;
  width: 80%;
  height: 33px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  border: solid 1px #fffbec;
  border-top: solid 1px #ceb98c;
  border-right: solid 1px #ceb98c;
  border-radius: 5px;
  background: linear-gradient(#fef7e6 30%, #ede2c4);
  text-indent: 5px;
  color: #694523;
}

input:focus {
  outline: #077BBB solid 2px;
}


select {
  display: block;
  width: 80%;
  height: 33px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  border: solid 1px #fffbec;
  border-top: solid 1px #ceb98c;
  border-right: solid 1px #ceb98c;
  border-radius: 5px;
  background: linear-gradient(#fef7e6 30%, #ede2c4);
  text-indent: 5px;
  color: #694523;
}

select:focus {
  outline: #077BBB solid 2px;
}









.desactivate {
  filter: saturate(0%);
  pointer-events: none;
}








.menuForMobile.bouton-icone {
  position: absolute;
  pointer-events: auto;

  right: 5px;
  transform: scale(1.3);
  z-index: 3;
}






































.premium {
  /*animation-iteration-count: infinite;
  animation-duration: 10s;
  animation-name: shining;
  animation-timing-function: linear;*/
  filter: drop-shadow(2px 2px 1px rgb(0, 0, 0, 0.2));
}

@keyframes shining {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);

  }
}

































.calque {
  display: block;
  background: linear-gradient(#FEF9E6, #FEEFC4);
  border-radius: 7px;

  border: solid 1px #E7D790;
  box-shadow: 0px 2px #A59655;

  padding: 5px;

  margin: 10px 0 10px 0;

}


.bouton-icone {
  height: 26px;
  width: 26px;

  content: '';
  padding: 0;
  position: relative;

  font-family: "icon";
  text-align: center;
  text-indent: 0px;
  display: block;
  line-height: 1.5em;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.4);
  color: #fff;
  text-decoration: none;
  font-size: 12pt;

  margin: 5px;
  ;

  border-radius: 5px;

  cursor: pointer;
  float: right;
}

.bouton-icone:dir(rtl) {
  text-indent: -1px;
  float: left;

}

.bouton-icone.language:dir(rtl) {
  text-indent: 0px;
}

.bouton-icone.bleu {
  background: linear-gradient(#88C5E5 30%, #077BBB);
  border: 1px solid #196589;
  box-shadow: -1px 2px 0px rgba(0, 0, 0, 0.3),
    inset 0px -2px 0 #196589;
  border-top: 1px solid #fff;
}

.bouton-icone.bleu:hover,
.bouton-icone.bleu:active {
  background: linear-gradient(#077BBB 30%, #88C5E5);
}

.bouton-icone.green {
  background: linear-gradient(#5FC86C 30%, #62955D);

  border: 1px solid #426932;
  border-top: 1px solid #fff;
  box-shadow: -1px 2px 0px rgba(0, 0, 0, 0.3),
    inset 0px -2px 0 #426932;
}

.bouton-icone.green:hover,
.bouton-icone.green:active {
  background: linear-gradient(#62955D 30%, #5FC86C);
}


.bouton-icone.bleu-green {
  background: linear-gradient(#6DC1AD 30%, #4B8C81);

  border: 1px solid #42675D;
  border-top: 1px solid #fff;
  box-shadow: -1px 2px 0px rgba(0, 0, 0, 0.3),
    inset 0px -2px 0 #42675D;
}

.bouton-icone.bleu-green:hover,
.bouton-icone.bleu-green:active {
  background: linear-gradient(#4B8C81 30%, #6DC1AD);

}

.bouton-icone.violet {
  background: linear-gradient(#B063EC 30%, #6741A8);

  border: 1px solid #461A56;
  border-top: 1px solid #fff;
  box-shadow: -1px 2px 0px rgba(0, 0, 0, 0.3),
    inset 0px -2px 0 #461A56;

}

.bouton-icone.violet:hover,
.bouton-icone.violet:active {
  background: linear-gradient(#6741A8 30%, #B063EC);

}

.bouton-icone.yellow {
  background: linear-gradient(#FFE015 30%, #D7C400);
  border: 1px solid #988D13;
  border-top: 1px solid #fff;
  box-shadow: -1px 2px 0px rgba(0, 0, 0, 0.3),
    inset 0px -2px 0 #988D13;
}

.bouton-icone.yellow:hover,
.bouton-icone.yellow:active {
  background: linear-gradient(#D7C400 30%, #FFE015);
}


.bouton-icone.red {
  background: linear-gradient(#F3304B 30%, #AF2027);
  border: 1px solid #7C0E11;
  border-top: 1px solid #fff;
  box-shadow: -1px 2px 0px rgba(0, 0, 0, 0.3),
    inset 0px -2px 0 #7C0E11;
}

.bouton-icone.red:hover,
.bouton-icone.red:active {
  background: linear-gradient(#AF2027 30%, #F3304B);
}

.bouton-icone.brown {
  background: linear-gradient(#E86342 30%, #B64336);
  border: 1px solid #541D06;
  border-top: 1px solid #fff;
  box-shadow: -1px 2px 0px rgba(0, 0, 0, 0.3),
    inset 0px -2px 0 #541D06;
}

.bouton-icone.brown:hover,
.bouton-icone.brown:active {
  background: linear-gradient(#B64336 30%, #E86342);
}

.bouton-icone.copper {
  color: #8C7D3F;
  text-shadow: -2px 2px 0px #FFF4D2;
  background: linear-gradient(#F3E8B5 20%, #DDC37F 80%);
  border: 1px solid #A59655;
  border-top: 1px solid #FCE8D0;
  box-shadow: -4px 4px 0px rgba(0, 0, 0, 0.1),
    inset 0px -2px 0 #A59655;
}

.bouton-icone.copper:hover,
.bouton-icone.copper:active {
  background: linear-gradient(#DDC37F 20%, #F3E8B5);
}















.bouton.small {

  height: 24px;
  width: auto;

  font-size: 9pt;

}




.bouton {
  height: 36px;
  width: fit-content;
  min-width: 160px;
  /* padding: 0 35px 0 35px;*/
  position: relative;
  font-family: 'Cooper';
  text-align: center;
  display: block;
  line-height: 2;

  font-size: 12pt;
  text-align: center;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  color: #fff;
  text-decoration: none;

  cursor: pointer;

  background: url(/public/assets/interfaces/motif-bouton-right.png) left no-repeat, url(/public/assets/interfaces/motif-bouton-left.png) right no-repeat, linear-gradient(#88C5E5 30%, #077BBB);


  border-radius: 7px;
  border: 1px solid #196589;
  border-top: 1px solid #fff;
  box-shadow: -1px 2px 0px rgba(0, 0, 0, 0.3),
    inset 0px -2px 0 #196589;
}

.bouton:hover,
.bouton:active {
  background: url(/public/assets/interfaces/motif-bouton-right.png) left no-repeat, url(/public/assets/interfaces/motif-bouton-left.png) right no-repeat, linear-gradient(#077BBB 30%, #88C5E5);
}



.bouton.simple {
  background: linear-gradient(#88C5E5 30%, #077BBB);
}


.bouton.simple:hover {
  background: linear-gradient(#077BBB 30%, #88C5E5);
}


.bouton.simple.green {
  background: linear-gradient(#71C377 30%, #5B975B);

  border: 1px solid #4C6D40;
  border-top: 1px solid #fff;
  box-shadow: -1px 2px 0px rgba(0, 0, 0, 0.3),
    inset 0px -2px 0 #4C6D40;
}


.bouton.simple.green:hover {
  background: linear-gradient(#5B975B 30%, #71C377);

}


.bouton.simple.bleu-green {
  background: linear-gradient(#6FC4AF 30%, #56978D);


  border: 1px solid #41645D;
  border-top: 1px solid #fff;
  box-shadow: -1px 2px 0px rgba(0, 0, 0, 0.3),
    inset 0px -2px 0 #41645D;
}


.bouton.simple.bleu-green:hover {
  background: linear-gradient(#56978D 30%, #6FC4AF);

}



.bouton.simple.copper {
  color: #8C7D3F;
  text-shadow: -2px 2px 0px #FFF4D2;
  background: linear-gradient(#F3E8B5 20%, #DDC37F 80%);
  border: 1px solid #A59655;
  border-top: 1px solid #FCE8D0;
  box-shadow: -4px 4px 0px rgba(0, 0, 0, 0.1),
    inset 0px -2px 0 #A59655;
}

.bouton.simple.copper:hover,
.bouton.simple.copper:active {
  background: linear-gradient(#DDC37F 20%, #F3E8B5);
}



.bouton.green {

  background: url(/public/assets/interfaces/motif-bouton-right.png) left no-repeat, url(/public/assets/interfaces/motif-bouton-left.png) right no-repeat, linear-gradient(#71C377 30%, #5B975B);


  border: 1px solid #4C6D40;
  border-top: 1px solid #fff;
  box-shadow: -1px 2px 0px rgba(0, 0, 0, 0.3),
    inset 0px -2px 0 #4C6D40;

}

.bouton.green:hover,
.bouton.green:active {

  background: url(/public/assets/interfaces/motif-bouton-right.png) left no-repeat, url(/public/assets/interfaces/motif-bouton-left.png) right no-repeat, linear-gradient(#5B975B 30%, #71C377);

}


.bouton.bleu-green {

  background: url(/public/assets/interfaces/motif-bouton-right.png) left no-repeat, url(/public/assets/interfaces/motif-bouton-left.png) right no-repeat, linear-gradient(#6FC4AF 30%, #56978D);


  border: 1px solid #41645D;
  border-top: 1px solid #fff;
  box-shadow: -1px 2px 0px rgba(0, 0, 0, 0.3),
    inset 0px -2px 0 #41645D;

}

.bouton.bleu-green:hover,
.bouton.bleu-green:active {

  background: url(/public/assets/interfaces/motif-bouton-right.png) left no-repeat, url(/public/assets/interfaces/motif-bouton-left.png) right no-repeat, linear-gradient(#56978D 30%, #6FC4AF);

}


.bouton.red.simple {

  background: linear-gradient(#F3304B 30%, #AF2027);


  border: 1px solid #7C0E11;
  border-top: 1px solid #fff;
  box-shadow: -1px 2px 0px rgba(0, 0, 0, 0.3),
    inset 0px -2px 0 #7C0E11;

}


.bouton.red.simple:hover {

  background: linear-gradient(#AF2027 30%, #F3304B);


  border: 1px solid #7C0E11;
  border-top: 1px solid #fff;
  box-shadow: -1px 2px 0px rgba(0, 0, 0, 0.3),
    inset 0px -2px 0 #7C0E11;

}




.bouton.red {

  background: url(/public/assets/interfaces/motif-bouton-right.png) left no-repeat, url(/public/assets/interfaces/motif-bouton-left.png) right no-repeat, linear-gradient(#F3304B 30%, #AF2027);


  border: 1px solid #7C0E11;
  border-top: 1px solid #fff;
  box-shadow: -1px 2px 0px rgba(0, 0, 0, 0.3),
    inset 0px -2px 0 #7C0E11;

}

.bouton.red:hover,
.bouton.red:active {

  background: url(/public/assets/interfaces/motif-bouton-right.png) left no-repeat, url(/public/assets/interfaces/motif-bouton-left.png) right no-repeat, linear-gradient(#AF2027 30%, #F3304B);

}












.bouton.gold {

  background: url(/public/assets/interfaces/motif-bouton-right-brun.png) left no-repeat, url(/public/assets/interfaces/motif-bouton-left-brun.png) right no-repeat, linear-gradient(#FFF79F 30%, #FFD543);


  color: #B39415;
  text-shadow: 1px 1px 3px rgba(255, 255, 255, 1);

  border: 1px solid #B39415;
  border-top: 1px solid #fff;
  box-shadow: -1px 2px 0px rgba(0, 0, 0, 0.3),
    inset 0px -2px 0 #B39415;

}

.bouton.gold:hover,
.bouton.gold:active {

  background: url(/public/assets/interfaces/motif-bouton-right-brun.png) left no-repeat, url(/public/assets/interfaces/motif-bouton-left-brun.png) right no-repeat, linear-gradient(#FFD543 30%, #FFF79F);

}



.bouton.gold.simple {

  background: linear-gradient(#FFF79F 30%, #FFD543);


  color: #B39415;
  text-shadow: 1px 1px 3px rgba(255, 255, 255, 1);

  border: 1px solid #B39415;
  border-top: 1px solid #fff;
  box-shadow: -1px 2px 0px rgba(0, 0, 0, 0.3),
    inset 0px -2px 0 #B39415;

}

.bouton.gold.simple:hover,
.bouton.gold.simple:active {

  background: linear-gradient(#FFD543 30%, #FFF79F);

}






@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0
  }

  100% {
    background-position: 800px 0
  }
}

.animated-background {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #f6f7f8;
  background: linear-gradient(to right, #FEEFC4 8%, #ffffff 18%, #FEEFC4 33%);
  background-size: 800px 104px;
  position: relative;
}











































.checkBox {
  height: 25px;
  width: 130px;
  padding: 0 35px 0 35px;
  position: relative;
  font-family: 'Cooper';
  text-align: center;
  display: block;
  line-height: 1.5em;
  text-indent: 20px;
  margin-left: auto;
  margin-right: auto;

  font-size: 12pt;
  text-align: center;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  color: #fff;
  text-decoration: none;

  border-radius: 15px;


  background: linear-gradient(#6FC4AF 30%, #56978D);


  border: 1px solid #41645D;
  border-top: 1px solid #fff;
  box-shadow: -1px 2px 0px rgba(0, 0, 0, 0.3),
    inset 0px -2px 0 #41645D;
}

.checkBox input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The knob */
.checkBox .knob {
  position: absolute;
  cursor: pointer;
  top: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  bottom: 0;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.checkBox .knob:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  inset-inline-start: 5px;
  bottom: 5px;
  background-color: white;

  background: linear-gradient(#56978D 30%, #6FC4AF);


  border: 1px solid #41645D;
  border-top: 1px solid #fff;
  box-shadow: -1px 2px 0px rgba(0, 0, 0, 0.3),
    inset 0px -2px 0 #41645D;


  -webkit-transition: .4s;
  border-radius: 34px;

  transition: .4s;
  z-index: 2;
}

/*
.checkBox input:checked + .knob {
  background: linear-gradient(#6FC4AF 30%, #56978D) ;
}
*/
.checkBox input:focus+.knob {
  box-shadow: 0 0 1px #2196F3;
}

.checkBox input:checked+.knob:before {
  transform: translateX(15px);
  border-radius: 50%;
  background: linear-gradient(#ffd000 30%, #ffff00);

}

.slideDeck {
  display: block;
  position: absolute;
  inset-inline-start: 7px;
  bottom: 7px;
  z-index: 1;

  height: 10px;
  width: 25px;
  text-decoration: none;

  border-radius: 15px;

  background: linear-gradient(#919191 30%, #e8e8e8);

  border: 1px solid #7e7e7e;
  border-top: 1px solid #fff;

}



























.panel .titre {
  white-space: nowrap;
}





/* Create a custom checkbox */













.titre {
  font-family: 'Cooper';
  font-size: 22pt;
  text-align: start;
  display: block;
  color: #FDDD50;
  text-shadow: 1px -1px 2px #764B1E, -1px 1px 2px #764B1E, 2px 2px 5px rgba(0, 0, 0, 0.2);
  float: left;
  width: 50%;
  /*margin-bottom: 10px;*/
}

.titre:dir(rtl) {
  float: right;

}

.sousTitre {
  font-weight: bold;
  text-align: start;
  color: #694523;
  font-size: 14px;
  text-transform: uppercase;
  height: fit-content;
  display: block;
  text-shadow: 0 1px 0 #fff;
}


.description {
  font-weight: bold;
  text-align: start;
  color: #694523;
  font-size: 14px;
  height: fit-content;
  display: block;
  text-align: center;
  text-shadow: 0 1px 0 #fff;
}

.message {
  font-weight: 400;
  text-align: start;
  color: #694523;
  font-size: 12px;
}







hr {
  clear: both;
  border: 1px solid white;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  margin-top: 5px;
  margin-bottom: 5px;
}

.vr {
  display: block;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid white;
  margin-right: 0px;
  margin-left: 0px;
  height: 100%;
  width: 2px;
  float: left;
}

.vr:dir(rtl) {
  float: right;
}













.AppContainer {
  position: absolute;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  pointer-events: none;
  width: 100%;
  height: 100%;
  /* background:rgba(0, 0, 0, 0.3);;*/
  margin: 0;
}


.panel .circle-wrap {
  margin-top: 7px;
}

.panelContainer {
  display: block;
  z-index: 1;
  position: relative;
  padding: 15px 15px 15px 15px;
}

.layer {
  clear: both;
  display: block;
  width: 100%;
  height: 250px;
  background: linear-gradient(#F9EBBC, #EAD78A);
  position: relative;
  border-radius: 50% / 2%;


  border-top: solid 2px #fff;
  border-bottom: solid 2px #D0C082;
  border-inline-end: none;
  border-inline-start: none;

  float: left;
}

.layer:dir(rtl) {
  float: right;

}

.layer:after {
  content: no-close-quote;

  position: absolute;
  top: 2%;
  bottom: 2%;
  inset-inline-end: -3.5%;
  inset-inline-start: -3.5%;
  border-radius: 3% / 50%;
  background: inherit;
  border: inherit;
  border-top: none;
  border-bottom: none;
  border-inline-end: solid 2px #fff;
  border-inline-start: solid 2px #D0C082;


}



.layerContainer {
  z-index: 1;
  position: relative;
  display: block;
  padding: 10px 5px 10px 5px;

}

.panel {
  position: relative;
  width: 620px;
  min-height: 350px;
  justify-content: center;
  align-items: center;

  user-select: none;

  background: linear-gradient(var(--base-color-trans), var(--base-color-trans)),
    radial-gradient(#F1E6BA 25%, transparent) center/90% 100% no-repeat,
    var(--pattern) center/100px repeat,
    linear-gradient(var(--base-color-trans), var(--base-color));
  border: #563315 solid 7px;

  border-radius: 50% / 5%;

  margin-left: auto;
  margin-right: auto;

  box-shadow: -0px 10px 10px rgba(0, 0, 0, 0.3),
    inset 7px 7px 0 #d8d1a6,
    inset -7px -7px 0 #8e7034;

  text-align: center;
  text-indent: .1em;

  content: normal;
  pointer-events: auto;

  z-index: 1;

}

.panel:after {
  content: no-close-quote;

  position: absolute;
  top: 3.5%;
  bottom: 3.5%;
  inset-inline-end: -6.3%;
  inset-inline-start: -6.3%;
  background: inherit;

  border: inherit;
  border-top: none;
  border-bottom: none;
  border-radius: 5% / 50%;

  box-shadow: inset 7px 0 0 #d8d1a6,
    inset -7px 0 0 #8e7034;
  z-index: 0;
}



@media only screen and (max-width: 800px),
(max-height: 475px) {
  .panel {
    transform: scale(0.9);
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}




.noshadow {
  box-shadow: none !important;
}