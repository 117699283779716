.versus {
    width: 550px;
}

.versus .titre {
    width: auto;

    display: none;
}

.versus .titleIcon:dir(rtl) {
    float: right;
}

.versus .titleIcon {
    display: block;
    float: left;
    width: 160px;
    height: 40px;
    position: relative;
}

.versus .titleIcon img {
    display: block;
    position: absolute;
    inset-inline-start: -30px;
    top: -30px;
    z-index: 3;
    width: 170px;
}

.versus .titleIcon .mode {
    display: block;
    position: absolute;
    inset-inline-end: 0;
    bottom: -10px;
    width: 80px;
    display: block;
    height: 40px;
    background: center/cover no-repeat url(/public/assets/icons/GameMenu02.png);
    z-index: 4;

}

.versus .layer {
    width: 100%;
    height: 220px;
    margin-bottom: 5px;
}

.versus .sousTitre {
    font-size: 12px;
    text-align: center;
}

.versus .bouton {
    margin-top: 15px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    clear: both;
}

.versus .bouton.desactivate {
    filter: saturate(0%);
    pointer-events: none;
}

.versus .contaninders {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 170px;
    margin-top: 10px;
}

.versus .vscontainer {
    display: block;
    margin-bottom: 5px;
    height: 40px;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
}

.versus .vscontainer img {
    width: auto;
    height: 35px;
    float: left;
    margin-right: 10px;
}

.versus .vscontainer .vs {
    width: 170px;
    height: 30px;
    padding: 3px;
    margin: 0;
    margin-left: auto;
    margin-right: auto;

    font-weight: bold;

    font-size: 22pt;
    display: block;
    color: #FDDD50;
    text-shadow: 1px -1px 2px #764B1E, -1px 1px 2px #764B1E, 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.versus .vscontainer .vs .mode {
    float: left;

    text-align: center;
    width: 50px;
    display: block;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    margin-left: 10px;

    background: center/cover no-repeat url(/public/assets/icons/GameMenu02.png);
}

.versus .iconBuck {
    display: block;
    position: absolute;
    top: -5px;
    inset-inline-start: -5px;
    width: 35px;
    height: 35px;
    background: center/cover no-repeat url(../../../public/assets/icons/pileOfMoney.png);
    z-index: 2;

}

.versus .vscontainer .reward {

    font-size: 16pt;
    display: block;
    width: 140px;
    height: auto;
    background: #ffffff;
    position: relative;
    border-radius: 5px;
    margin-top: 3px;

    margin-left: auto;
    margin-right: auto;

    color: #76a715;
    text-shadow: -1px 1px 2px #325201;

    border-top: solid 2px #fff;
    border-bottom: solid 2px #D0C082;
    border-inline-end: none;
    border-inline-start: none;

}