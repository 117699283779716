
.soundToolTip{
    top:0;
   inset-inline-start:0;
    display: block;
    position: absolute;
    transform: translateX(-101%) translateY(-5px);
    width: 210px;
    height: 35px;
    pointer-events: auto;
   inset-inline-end:60px;
    margin: 0;
    margin-top: auto;
    margin-bottom: auto;

    border-radius: 7px;
    background:linear-gradient(#F3E8B5 20%, #DDC37F 80%) ;
    border: 1px solid #A59655;
    border-top: 1px solid #FCE8D0;
    box-shadow: -4px 4px 0px  rgba(0, 0, 0, 0.1) ,
    inset 0px -2px 0 #A59655;

    z-index: 10;

}

.soundToolTip:dir(rtl){
    transform: translateX(101%) translateY(-5px);
}

.soundToolTip .triangle{
    display: block;
    height: 0;
    width: 0;
    float: right;
    margin-inline-end : -5px;
    margin-top: 10px;
    background:linear-gradient(#F3E8B5 20%, #DDC37F 80%) ;

    border-top:  5px solid transparent;
    border-inline-start:  5px solid #F3E8B5;
    border-bottom: 5px solid transparent;

}
.soundToolTip .triangleMosaique{
    display: block;
    height: 30px;
    width: 30px;
    float: right;
    background: url(/public/assets/interfaces/motif-bouton-left-brun.png) left no-repeat;
}

.soundToolTip .bouton-icone{
    margin: 2px;
}

.soundToolTip .fleche{
    width: 28px;
    height: 28px;
    float: right;
    margin: 3px;
}

.soundToolTip .fleche::before{ 
    top:  1px;
   inset-inline-start: 2px; 
}
  

.soundToolTip .fleche:dir(rtl),
.soundToolTip .triangleMosaique:dir(rtl),
.soundToolTip .triangle:dir(rtl)
{
    float: left;
}