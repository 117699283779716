.conditions {
    width: 660px;
    min-height: 400px;
}

.conditions .layer {
    height: 285px;
}

.conditions .titre {
    margin: 0px
}

.conditions .onglet {
    clear: both;
    list-style-type: none;
    float: left;
    margin: 0;
    padding: 0;
    margin-top: 10px;
    margin-bottom: -4px;
    position: relative;

}

.conditions .dummyItem {
    display: block;
    width: 100%;
    height: 34px;
    /* background: linear-gradient(#FEF9E6, #FEEFC4);
  
  border: solid 1px #E7D790;
  box-shadow: 0px 2px #A59655; */
    border-radius: 7px;
    padding: 0;
    overflow: hidden;
    float: none;
    margin-bottom: 9px;
}


.conditions .onglet:dir(rtl) {
    float: right;
}

.conditions .onglet li {
    display: block;
    float: right;
    cursor: pointer;
    font-size: 13px;
    font-weight: bold;
    color: #644629;
    text-shadow: 0 2px 1px #fff;

    /* 
    border-radius: 3%/50%;
    border: solid 2px #fff;
    border-left: solid 2px #cec08a;
    border-bottom: none; 
    background-color: #f7ebc1;
   
    width: 110px; */
    height: 25px;

    line-height: 2em;

    padding: 5px 15px 5px 15px;
    margin-inline-end: -15px;
    position: relative;
    z-index: 0;
    background: center/cover no-repeat url(../../../public/assets/interfaces/onglet-Off.png);
    background-size: 100% 100%;
    white-space: nowrap;
    overflow: hidden;
}

.conditions .onglet li:dir(rtl),
.conditions .fleche:dir(rtl) {
    float: left;
}


.conditions .onglet li:hover,
.conditions .onglet li.active {
    background: center/cover no-repeat url(../../../public/assets/interfaces/onglet-On.png);
    background-size: 100% 100%;

    z-index: 3;
}

.conditions .layerNone {
    display: none;
}

::-webkit-scrollbar-thumb {
    background-color: #644629;
    border-radius: 5px;
}

/* Couleur de la piste (track) */
::-webkit-scrollbar-track {

    background-color: transparent;
    border-radius: 5px;
}



.conditions .textScroll {
    display: block;
    width: 98%;
    height: 250px;
    overflow: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;

    font-size: 13px;
    color: #644629;
    text-align: left;
    background-color: rgba(255, 255, 255, 0.3);
    padding: 10px;
    padding-top: 5px;
    border-radius: 15px;

}

.conditions .fleche {
    width: 25px;
    float: right;
    margin-inline-end: 8px;
}