.inviteTooltip {
    bottom: 25px;
    display: block;
    position: fixed;
    width: 150px;
    height: 60px;
    pointer-events: auto;
    margin: 0;

    border-radius: 7px;
    background: linear-gradient(#F3E8B5 20%, #DDC37F 80%);
    border: 1px solid #A59655;
    border-top: 1px solid #FCE8D0;
    box-shadow: -4px 4px 0px rgba(0, 0, 0, 0.1),
        inset 0px -2px 0 #A59655;

    padding: 10px;
    z-index: 10;

    transform: translateX(-70%) translateY(-100%);
    pointer-events: auto;
}


.inviteTooltip .titre {
    font-size: 14pt;
    position: absolute;
    top: -15px;
    width: 90%;
    line-height: 0.7em;
    text-align: center;
    z-index: 2;
}

.inviteTooltip .triangle {
    display: block;
    height: 0;
    width: 0;

    margin-bottom: -5px;


    border-inline-end: 5px solid transparent;
    border-inline-start: 5px solid transparent;
    border-top: 10px solid #DDC37F;
    transform: translateX(-5px);

    margin-left: auto;
    margin-right: auto;
    position: absolute;
    bottom: 0;
    inset-inline-start: 50%;
}

.inviteTooltip .triangle::before {
    display: block;
    position: absolute;

    margin-bottom: -8px;
    transform: rotate(90deg) translateX(-5px);


    border-inline-end: 10px solid transparent;
    border-inline-start: 10px solid transparent;
    border-top: 20px solid #A59655;
}

.inviteTooltip .triangle:dir(rtl) {
    transform: translateX(5px);

}

.inviteTooltip .triangleMosaique {
    display: block;
    height: 30px;
    width: 30px;
    position: absolute;
    bottom: 0;
    inset-inline-start: 50%;
    transform: rotate(90deg) translateY(50%);
    margin-left: auto;
    margin-right: auto;
    background: url(/public/assets/interfaces/motif-bouton-left-brun.png) left no-repeat;
}

.inviteTooltip .triangleMosaique:dir(rtl) {
    transform: rotate(90deg) translateY(-50%);

}

.inviteTooltip .inviteContainer {
    position: relative;
    display: block;
    width: 100%;
    height: 80%;

    margin-left: auto;
    margin-right: auto;



}

.inviteTooltip .bouton.small.simple {
    width: 70px;
    min-width: 100px;
    float: none;
    margin-right: auto;
    margin-left: auto;
    pointer-events: auto;
}

.inviteTooltip .message {
    display: block;
    font-size: 12px;
    font-weight: bold;
    color: #644629;
    text-shadow: 0 2px 1px #fff;

    text-align: center;
    margin-bottom: 5px;
    width: 100%;
    height: fit-content;
    float: none;
}