.notificationpermission {
  position: absolute;
  width: 400px;
  min-height: 230px;

}

.notificationpermission .perso {
  display: block;
  width: 240px;
  height: 120px;
  position: absolute;
  top: -100px;
  right: 50px;
  background: center/cover no-repeat url(/public/assets/interfaces/persoMouche.png);
}

.notificationpermission .perso:dir(rtl) {
  transform: scaleX(-100%);
  float: left;

}

.notificationpermission .bouton {
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  width: 200px;
}

.notificationpermission .layer {
  width: 100%;
  height: 150px;
  float: left;
}

.notificationpermission .layer:dir(rtl) {
  float: left;
}