.indicateurTournamentPlayer {
    display: block;
    width: 130px;
    background: linear-gradient(#FEF9E6, #FEEFC4);

    position: relative;
    border: solid 1px #E7D790;
    box-shadow: 0px 2px #A59655;
    padding: 4px;
    margin-top: 5px;
    border-radius: 10px;
    float: left;


}

.indicateurTournamentPlayer .showbot {
    display: block;
    width: 15px;
    height: 15px;
    position: absolute;
    bottom: 1px;
    inset-inline-start: 1px;
    background: center/cover no-repeat url(/public/assets/icons/bot.png);
}



.indicateurTournamentPlayer .result {
    display: block;
    height: 40px;
    width: 40px;
    overflow: hidden;
    position: absolute;
    margin: 10px;
    top: 0;
    transform: translate(-10px, -30px);
    z-index: 3;
}


.indicateurTournamentPlayer .result.Win {
    background: center/cover no-repeat url(../../../public/assets/icons/Result-Win.png);
}





.indicateurTournamentPlayer .imageContainer {
    display: block;
    height: 40px;
    width: 40px;
    border-radius: 7px;
    overflow: hidden;
    position: relative;
    float: left;

}

.indicateurTournamentPlayer .imageContainer .refrac {
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    background: center/cover no-repeat url("/public/assets/interfaces/refractionEffect.png");
}

.indicateurTournamentPlayer .imageContainer .image {
    display: block;
    height: 100%;
    width: 100%;

}

.indicateurTournamentPlayer .imageContainer .flag {
    display: block;
    height: 15px;
    width: auto;
    position: absolute;
    bottom: 0;
    right: 0;
}

.indicateurTournamentPlayer .level {
    display: block;
    width: 30px;
    height: 22px;
    background: center/cover no-repeat url(/public/assets/icons/Level.png);
    z-index: 2;

    color: #e5d796;
    text-shadow: -1px 1px 2px #77006b;
    text-align: center;
    font-weight: bolder;
    line-height: 1.2em;
    float: left;
    margin-top: 3px;
    margin-left: 3px;

}

.indicateurTournamentPlayer .name {
    font-weight: 400;
    text-align: start;
    color: #694523;
    font-size: 12px;
    text-align: center;
    float: left;
    display: block;
    width: 90px;
    height: 13px;
    overflow: hidden;
    margin-bottom: 2px;
}




.indicateurTournamentPlayer .bouton-bet {
    height: 22px;

    padding: 0;
    position: relative;

    text-align: center;
    text-indent: 10px;
    display: block;
    line-height: 1.5em;
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.4);
    color: #fff;
    text-decoration: none;
    font-size: 10pt;
    font-weight: bold;

    border-radius: 5px;


    width: 50px;
    background: linear-gradient(#86ccbb 30%, #5a9f93);

    border: 1px solid #41645d;
    border-top: 1px solid #fff;
    box-shadow: -1px 2px 0px rgba(0, 0, 0, 0.5),
        inset 0px -2px 0 #41645d;

    margin-left: 5px;
    margin-right: auto;
    float: left;

    /* inset-inline-end: 50%;
    transform: translateX(-50%);
*/
}

.indicateurTournamentPlayer .bouton-bet .iconCoin {
    display: block;
    position: absolute;
    top: 2px;
    inset-inline-start: -2px;
    width: 25px;
    height: 30px;
    background: center/cover no-repeat url(/public/assets/icons/Coin.png);
    z-index: 2;
}


.indicateurTournamentPlayer .bouton-bet .iconCoin.buck {
    top: 0px;
    inset-inline-start: -4px;

    width: 25px;
    height: 25px;
    background: center/cover no-repeat url(/public/assets/icons/pileOfMoney.png);
}

.indicateurTournamentPlayer .indicateurPremium {
    position: absolute;
    top: -20px;
    left: -20px;
    z-index: 3;
}


.indicateurTournamentPlayer .percent {
    display: block;
    width: 100%;

    position: absolute;
    bottom: 0;
    color: #e5d796;
    text-shadow: -1px 1px 2px #77006b;
    text-align: center;
    font-weight: bolder;
    z-index: 2;
    font-size: 10px;

}

.indicateurTournamentPlayer .slide {
    display: block;
    width: 100px;
    height: 10px;

    /*border: solid 2px #fff;*/
    border-radius: 5px;
    background: linear-gradient(#af38c8 30%, #c43edf);
    box-shadow: inset 0 3px 0 0 #e8c5ee;


    inset-inline-start: 0;
    position: absolute;
    bottom: 0;
}


.indicateurTournamentPlayer .online {
    display: block;
    width: 7px;
    height: 7px;
    border-radius: 10px;
    background-color: #a49453;
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    border: solid 1px #bfae81;
    border-bottom: solid 1px #e7e5de;
}

.indicateurTournamentPlayer .online.latency3 {
    background-color: #15ff00;
    box-shadow: 0 0 5px 3px #15ff00;
}

.indicateurTournamentPlayer .online.latency2 {
    background-color: #ffd900;
    box-shadow: 0 0 5px 3px #ffd900;
}

.indicateurTournamentPlayer .online.latency1 {
    background-color: #ff0000;
    box-shadow: 0 0 5px 3px #ff0000;
}

.indicateurTournamentPlayer .online.latency0 {
    background-color: #a49453;
}

.indicateurTournamentPlayer .bouton-icone {
    height: 20px;
    width: 20px;
    font-size: 11pt;
    line-height: 1.4em;
    position: absolute;
    bottom: 4px;
    inset-inline-end: 4px;
    z-index: 4;
}