@keyframes sprite {
    to {
        background-position: -1800px
    }
}

#loadingScreen.mini {
    backdrop-filter: none;
    background: none;
    display: block;
}

#loadingScreen.mini #spin span {
    transform: scale(0.5);
}

#loadingScreen.mini #spin {
    margin: 0;
}


#loadingScreen,
#loadingScreen.full {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    /* backdrop-filter: saturate(40%) blur(5px); */
    /*
    background: radial-gradient( transparent 25%, rgba(0, 0, 0, 0.3)) center/100% 100% no-repeat,
    url(/public/assets/themes/interfaces/classique/Floor.jpg) center/100px repeat;
  */
    background: rgba(0, 0, 0, 0.3);
    pointer-events: none;

}

#spin,
#spin.full {
    display: block;
    height: 90px;
    width: 90px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

#spin span {
    display: block;
    height: 90px;
    width: 90px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    background: url(/public/assets/interfaces/loading.png);
    animation: sprite 1s steps(10) infinite;

}