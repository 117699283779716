.IndicateurLatency .online {
    display: block;
    width: 7px;
    height: 7px;
    border-radius: 10px;
    background-color: #a49453;
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    border: solid 1px #bfae81;
    border-bottom: solid 1px #e7e5de;
    z-index: 3;
}

.IndicateurLatency .online.latency3 {
    background-color: #15ff00;
    box-shadow: 0 0 5px 3px #15ff00;
}

.IndicateurLatency .online.latency2 {
    background-color: #ffd900;
    box-shadow: 0 0 5px 3px #ffd900;
}

.IndicateurLatency .online.latency1 {
    background-color: #ff0000;
    box-shadow: 0 0 5px 3px #ff0000;
}

.IndicateurLatency .online.latency0 {
    background-color: #a49453;
}