.modeMulti {
    display: block;
}

.modeMulti .layer {
    width: 48%;
    clear: right;
    height: 270px;
    margin-bottom: 5px;
}

.modeMulti .layer.second {
    float: right;
}


.modeMulti .titre {
    width: 76%;
}

.modeMulti .sousTitre {
    font-size: 12px;
    text-align: center;
}

.modeMulti .calque .sousTitre {
    text-align: start;
    font-size: 10pt;
}

.modeMulti .calque {
    margin-top: 0;
    padding: 10px;
}


.modeMulti .numero {
    font-weight: bold;
    text-align: start;
    color: #694523;
    font-size: 40px;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.4em;
}

.modeMulti .itemsContainer {
    display: block;
    margin: 0;
    margin-bottom: 10px;
    margin-top: 10px;

    padding: 0;
    height: 170px;
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
}

.modeMulti .bouton {
    margin-top: 10px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    clear: both;
}

.modeMulti .switch {
    margin-top: 20px;
}

.modeMulti .checkBox {
    margin-top: 10px;
}

.modeMulti .stepper {
    margin-bottom: 15px;
}



.modeMulti .form input {
    text-indent: 30px;
}

.modeMulti .calque.form .formContainer {
    display: block;
    position: relative;
}

.modeMulti .calque.form i.icon-ok {
    inset-inline-end: 25px;
    width: 1em;
    inset-inline-start: unset;
    color: rgb(0, 200, 0);

}

.modeMulti .calque.form i.icon-cancel,
.modeMulti .calque.form i.icon-notavailable {
    inset-inline-end: 25px;
    width: 1em;
    inset-inline-start: unset;
    color: rgb(200, 0, 0);

}

.modeMulti .calque.form i {
    display: inline;
    padding: 10px;
    color: #694523;
    text-align: center;
    position: absolute;
    inset-inline-start: 18px;
}

.modeMulti .form .bouton {
    margin-right: auto;
    margin-left: auto;
    width: 212px;

}

.modeMulti .formContainer span {
    display: block;
    height: 30px;
    width: fit-content;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    line-height: 2.5em;
    font-size: 14px;
    color: #694523;
}


.modeMulti .bouton-store {
    height: 23px;

    padding: 0;
    position: relative;

    text-align: center;
    text-indent: 10px;
    display: block;
    line-height: 1.5em;
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.4);
    color: #fff;
    text-decoration: none;
    font-size: 11pt;
    font-weight: bold;

    border-radius: 5px;

    cursor: pointer;

    width: 90px;
    background: linear-gradient(#86ccbb 30%, #5a9f93);

    border: 1px solid #41645d;
    border-top: 1px solid #fff;
    box-shadow: -1px 2px 0px rgba(0, 0, 0, 0.5),
        inset 0px -2px 0 #41645d;

    inset-inline-start: 5px;

    pointer-events: none;

    float: left;
    margin-left: 10px;
    margin-bottom: 10px;
    /* inset-inline-end: 50%;
    transform: translateX(-50%);
*/
}

.modeMulti .message {
    display: block;
    float: left;
    text-align: center;
    margin-top: 5px;
    margin-left: 5px;
    display: block;
    margin-bottom: 5px;
}

.modeMulti .bouton-store .iconCoin {
    display: block;
    position: absolute;
    top: -2px;
    inset-inline-start: -2px;
    width: 25px;
    height: 45px;
    background: center/cover no-repeat url(/public/assets/icons/Coin.png);
    z-index: 2;
}