.AlertAchievement .perso {
    display: block;
    width: 120px;
    height: 280px;
    background: center/cover no-repeat url(/public/assets/interfaces/persoMan.png);
    float: right;
    margin-inline-end: -90px;
}

.AlertAchievement .perso:dir(rtl) {
    float: left;
}

.AlertAchievement .perso:dir(rtl) {
    transform: scaleX(-100%);
}


.AlertAchievement .layer {
    width: 275px;
    float: left;
}

.AlertAchievement .layer:dir(rtl) {
    float: right;

}

.AlertAchievement .description {
    margin-bottom: 10px;
}

.AlertAchievement .itemAchievement {
    display: block;

    width: 100%;
    height: 175px;

    border: solid 2px #fff;
    border-radius: 10px;
    background: linear-gradient(rgba(256, 256, 256, 0.8), rgba(256, 256, 256, 0.2));


    box-shadow: -4px 4px 0 rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin: 0px;


    position: relative;

}

.AlertAchievement .bouton {
    position: absolute;
    bottom: -40px;
    left: 20%;
}

.itemAchievement img {
    height: 90%;
    width: auto;
}


.AlertAchievement .shine {
    background: center / contain no-repeat url(/public/assets/interfaces/shine.png);
    display: block;
    width: 600px;
    height: 600px;
    position: absolute;
    animation-iteration-count: infinite;
    animation-duration: 10s;
    animation-name: shining;
    animation-timing-function: linear;
    z-index: -1;
    top: -200px;
    left: -140px;
    pointer-events: none;
}