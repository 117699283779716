.invitation {
  position: absolute;
  width: 400px;
}

.invitation .description {
  margin-bottom: 10px;
}

.invitation .perso {
  display: block;
  width: 115px;
  height: 270px;
  background: center/cover no-repeat url(/public/assets/interfaces/persoMan.png);
  float: right;
  margin-inline-end: -40px;
}

.invitation .perso:dir(rtl) {
  transform: scaleX(-100%);
  float: left;

}

.invitation .layer {
  width: 275px;
  height: 270px;
  float: left;
}

.invitation .layer:dir(rtl) {
  float: left;
}

.invitation span.itemStore {
  display: block;

  width: 100%;
  height: 175px;

  border: solid 2px #fff;
  border-radius: 10px;
  background: linear-gradient(rgba(256, 256, 256, 0.8), rgba(256, 256, 256, 0.2));
  ;

  box-shadow: -4px 4px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin: 0px;


  position: relative;

}

.invitation .indicateur {
  margin-top: 20px;
  transform: scale(130%);
  margin-inline-end: 40px;
}


.invitation .fleches {
  width: fit-content;
  transform: rotate(-90deg) translate(100%, 0%);
  margin-left: auto;
  margin-right: auto;
}

.invitation .priceTag {
  height: 23px;

  padding: 0;
  position: relative;

  text-align: center;
  text-indent: 10px;
  display: block;
  line-height: 1.5em;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.4);
  color: #fff;
  text-decoration: none;
  font-size: 11pt;
  font-weight: bold;

  border-radius: 5px;

  cursor: pointer;

  position: absolute;
  bottom: 5px;
  width: 90px;
  background: linear-gradient(#86ccbb 30%, #5a9f93);

  border: 1px solid #41645d;
  border-top: 1px solid #fff;
  box-shadow: -1px 2px 0px rgba(0, 0, 0, 0.5),
    inset 0px -2px 0 #41645d;



  inset-inline-end: 10px;

}

.invitation .priceTag .iconCoin {
  display: block;
  position: absolute;
  top: -2px;
  inset-inline-start: -2px;
  width: 25px;
  height: 45px;
  background: center/cover no-repeat url(/public/assets/icons/Coin.png);
  z-index: 2;
}