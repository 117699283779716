.indicateurPremium{
    display: block;
    /*transform: rotate(22deg);
    background: center/cover no-repeat url(../../public/assets/icons/Star.png);*/
    height: 50px;
    width: 50px; 
}

.indicateurPremium img{
    width: 100%;
    height: 100%;
}
