span.itemStore {
    display: block;

    width: 100px;
    height: 90px;

    border: solid 2px #fff;
    border-radius: 10%;
    background: linear-gradient(rgba(256, 256, 256, 0.8), rgba(256, 256, 256, 0.2));
    ;

    box-shadow: -4px 4px 0 rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin: 0px;
    margin-bottom: 8px;
    margin-inline-end: 12px;

    position: relative;
}

.itemStore .bouton-store {
    height: 23px;

    padding: 0;
    position: relative;

    text-align: center;
    text-indent: 10px;
    display: block;
    line-height: 1.5em;
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.4);
    color: #fff;
    text-decoration: none;
    font-size: 11pt;
    font-weight: bold;

    border-radius: 5px;

    cursor: pointer;

    position: absolute;
    bottom: 5px;
    width: 90px;
    background: linear-gradient(#86ccbb 30%, #5a9f93);

    border: 1px solid #41645d;
    border-top: 1px solid #fff;
    box-shadow: -1px 2px 0px rgba(0, 0, 0, 0.5),
        inset 0px -2px 0 #41645d;

    inset-inline-start: 5px;

    pointer-events: auto;

    /* inset-inline-end: 50%;
    transform: translateX(-50%);
*/
}

.itemStore .bouton.small {
    position: absolute;
    bottom: 5px;
    width: 90px;
    min-width: auto;
    inset-inline-start: 5px;
    pointer-events: auto;

}

.bouton-store.green:hover,
.bouton-icone.green:active {
    background: linear-gradient(#5a9f93 30%, #86ccbb);
}

.store .itemStore .desactivate {
    filter: saturate(0%);
    pointer-events: none;
}


.itemStore span.count {
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: crimson;
    border: #fff solid 2px;
    font-size: 10px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    line-height: 1.5em;
    box-shadow: -3px 3px 0px rgba(0, 0, 0, 0.2);
    inset-inline-end: 5px;
    top: 5px;
    z-index: 3;
}


.bouton-store .iconCoin {
    display: block;
    position: absolute;
    top: -2px;
    inset-inline-start: -2px;
    width: 25px;
    height: 45px;
    background: center/cover no-repeat url(/public/assets/icons/Coin.png);
    z-index: 2;
}

.itemStore img {
    max-width: 70%;
    /* Remplacez 100px par la taille maximale souhaitée en largeur */
    max-height: 70%;
    /* Remplacez 100px par la taille maximale souhaitée en hauteur */
    width: auto;
    height: auto;
    /* width: 65%;
    height: auto; */
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
    border-radius: 5px;
    -webkit-filter: drop-shadow(-3px 3px 2px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(-3px 3px 2px rgba(0, 0, 0, 0.2));
}