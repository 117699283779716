.itemRoom {
    display: block;

    width: 100%;
    height: 43px;
    padding: 0;
    margin: 0;
}


.itemRoomContainer {
    display: block;

    width: 100%;
    height: 34px;
    background: linear-gradient(#FEF9E6, #FEEFC4);
    border-radius: 7px;

    border: solid 1px #E7D790;
    box-shadow: 0px 2px #A59655;
    padding: 0;

    float: none;

}

.itemRoomContainer .index {
    display: block;
    margin: 0;
    float: left;
    width: 50px;
    height: 34px;
    border-radius: 7px 0 0 7px;
    color: white;
    text-shadow: 0 0 2px #3c2b15;
    font-size: 30px;
    line-height: 1em;
    font-weight: bolder;


    background: linear-gradient(#F3E8B5 20%, #DDC37F 80%);
    border: 1px solid #A59655;
    border-top: 1px solid #FCE8D0;
    box-shadow: inset 0px -2px 0 #A59655;
}

.itemRoomContainer .index:dir(rtl),
.itemRoomContainer .imageContainer:dir(rtl),
.itemRoomContainer .name:dir(rtl),
.itemRoomContainer .Level:dir(rtl),
.itemRoomContainer .Level .ico:dir(rtl),
.itemRoomContainer .Level .ico:dir(rtl),
.itemRoomContainer .Achievement:dir(rtl),
.itemRoomContainer .Achievement .ico:dir(rtl),
.itemRoomContainer .Coin:dir(rtl),
.itemRoomContainer .Win .ico:dir(rtl),
.itemRoomContainer .Coin .ico:dir(rtl),
.itemRoomContainer .premium .ico:dir(rtl) {


    float: right;


}

.itemRoomContainer .flag:dir(rtl),
.huitemRoomContainerd .name:dir(rtl) {
    float: right;
}


.itemRoomContainer .flag {
    display: block;
    height: 15px;
    width: 20px;
    position: relative;
    border-radius: 2px;
    float: left;
    margin: 2px;
    margin-top: 10px;
}


.itemRoomContainer .imageContainer {
    width: 32px;
    height: 32px;
    margin-top: 1px;
    margin-inline-start: 5px;
    float: left;
    position: relative;
    overflow: hidden;
    border-radius: 6px;

}



.itemRoomContainer .name {
    height: 34px;
    line-height: 2.7em;

    float: left;
    margin-inline-start: 5px;

    font-size: 14px;
    font-weight: 700;

    color: #694523;
    display: block;
    width: 160px;
    overflow: hidden;
}


.itemRoomContainer .Rounds {
    display: block;
    height: 100%;
    width: 45px;
    float: left;
    line-height: 2.7em;

    margin-inline-start: 5px;
    font-size: 14px;
    font-weight: 700;
    color: #694523;
}

.itemRoomContainer .Rounds .ico {
    display: block;
    height: 25px;
    width: 25px;
    margin-top: 7px;
    float: left;
    background: center/cover no-repeat url(/public/assets/icons/Rounds.png);
}


.itemRoomContainer .Players {
    display: block;
    height: 100%;
    width: 55px;
    float: left;
    line-height: 2.7em;

    margin-inline-start: 5px;
    font-size: 14px;
    font-weight: 700;
    color: #694523;
}

.itemRoomContainer .Players .ico {
    display: block;
    height: 25px;
    width: 25px;
    margin-top: 6px;
    float: left;
    background: center/cover no-repeat url(/public/assets/icons/Players.png);
}


.itemRoomContainer .Specialmode {
    display: block;
    height: 100%;
    width: 45px;
    float: left;
    line-height: 2.7em;

    margin-inline-start: 5px;
    font-size: 14px;
    font-weight: 700;
    color: #694523;
}

.itemRoomContainer .Specialmode .ico {
    display: block;
    height: 26px;
    width: 24px;
    margin-top: 4px;
    float: left;
    background: center/cover no-repeat url(/public/assets/icons/Achievement.png);
}


.itemRoomContainer .Password {
    display: block;
    height: 100%;
    width: 90px;
    float: right;
    line-height: 2.7em;

    margin-inline-start: 5px;
    font-size: 14px;
    font-weight: 700;
    color: #694523;
}

.itemRoomContainer .Password .ico {
    display: block;
    height: 22px;
    width: 22px;
    margin-top: 7px;
    float: left;
    background: center/cover no-repeat url(/public/assets/icons/Password.png);
}

.itemRoomContainer .Password .ico.on {
    background: center/cover no-repeat url(/public/assets/icons/Password-On.png);
}

.itemRoomContainer .bouton-icone {
    margin: 3px;
    margin-inline-end: 6px;
    float: left;
}

.itemRoomContainer .inputPassword {
    margin: 0;
    float: left;
    width: 40px;
    height: 25px;
    margin-top: 3px;
    margin-left: 5px;
}

.itemRoomContainer .bouton.small.simple {
    margin: 3px;
    margin-inline-end: 6px;
    float: right;
    min-width: 85px;
}

.itemRoomContainer .checkBox {
    float: left;
    margin-top: 3px;

    pointer-events: none;

    width: 50px;
    transform: scale(0.75);
    margin: -10px;
    margin-top: 3px;
}

.itemRoomContainer span.bouton {
    pointer-events: none;
}