.indicateurMultiPlayer {
    display: block;
    width: 100px;
    background: linear-gradient(#FEF9E6, #FEEFC4);

    position: relative;
    border: solid 1px #E7D790;
    box-shadow: 0px 2px #A59655;
    padding: 7px;
    border-radius: 10px;


}

.indicateurMultiPlayer .imageContainer {
    display: block;
    height: 100px;
    width: 100px;
    border-radius: 7px;
    overflow: hidden;
    position: relative;
}

.indicateurMultiPlayer .imageContainer .refrac {
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    background: center/cover no-repeat url("/public/assets/interfaces/refractionEffect.png");
}

.indicateurMultiPlayer .imageContainer .image {
    display: block;
    height: 100%;
    width: 100%;

}

.indicateurMultiPlayer .imageContainer .flag {
    display: block;
    height: 15px;
    width: auto;
    position: absolute;
    bottom: 0;
    right: 0;
}

.indicateurMultiPlayer .imageContainer .level {
    display: block;
    width: 39px;
    height: 26px;
    background: center/cover no-repeat url(/public/assets/icons/Level.png);
    z-index: 2;

    color: #e5d796;
    text-shadow: -1px 1px 2px #77006b;
    text-align: center;
    font-weight: bolder;
    line-height: 1.4em;

    position: absolute;
    bottom: 0;
    left: 0;
}

.indicateurMultiPlayer .name {
    font-weight: 400;
    text-align: start;
    color: #694523;
    font-size: 12px;
    text-align: center;
}




.indicateurMultiPlayer .bouton-ready {
    height: 23px;

    padding: 0;
    position: relative;

    text-align: center;
    display: block;
    line-height: 1.5em;
    text-decoration: none;
    font-size: 11pt;
    font-weight: bold;

    border-radius: 5px;

    color: #8C7D3F;
    text-shadow: 2px 2px 1px rgba(255, 255, 255, 1);

    width: 90px;
    background: linear-gradient(#F3E8B5 20%, #DDC37F 80%);
    border: 1px solid #A59655;
    border-top: 1px solid #FCE8D0;
    box-shadow: -4px 4px 0px rgba(0, 0, 0, 0.1),
        inset 0px -2px 0 #A59655;

    margin-left: auto;
    margin-right: auto;

    /* inset-inline-end: 50%;
    transform: translateX(-50%);
*/
}

.indicateurMultiPlayer .bouton-ready.start {
    color: #fff;
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.4);

    background: linear-gradient(#88C5E5 30%, #077BBB);
    border: 1px solid #196589;
    box-shadow: -1px 2px 0px rgba(0, 0, 0, 0.3),
        inset 0px -2px 0 #196589;
    border-top: 1px solid #fff;
}



.indicateurMultiPlayer .indicateurPremium {
    position: absolute;
    top: -20px;
    left: -20px;
    z-index: 3;
}


.indicateurMultiPlayer .percent {
    display: block;
    width: 100%;

    position: absolute;
    bottom: 0;
    color: #e5d796;
    text-shadow: -1px 1px 2px #77006b;
    text-align: center;
    font-weight: bolder;
    z-index: 2;
    font-size: 10px;

}

.indicateurMultiPlayer .slide {
    display: block;
    width: 100px;
    height: 10px;

    /*border: solid 2px #fff;*/
    border-radius: 5px;
    background: linear-gradient(#af38c8 30%, #c43edf);
    box-shadow: inset 0 3px 0 0 #e8c5ee;


    inset-inline-start: 0;
    position: absolute;
    bottom: 0;
}


.indicateurMultiPlayer .online {
    display: block;
    width: 7px;
    height: 7px;
    border-radius: 10px;
    background-color: #a49453;
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    border: solid 1px #bfae81;
    border-bottom: solid 1px #e7e5de;
}

.indicateurMultiPlayer .online.latency3 {
    background-color: #15ff00;
    box-shadow: 0 0 5px 3px #15ff00;
}

.indicateurMultiPlayer .online.latency2 {
    background-color: #ffd900;
    box-shadow: 0 0 5px 3px #ffd900;
}

.indicateurMultiPlayer .online.latency1 {
    background-color: #ff0000;
    box-shadow: 0 0 5px 3px #ff0000;
}

.indicateurMultiPlayer .online.latency0 {
    background-color: #a49453;
}

.indicateurMultiPlayer .bouton-icone {
    transform: scale(0.7);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
}