.modeSolo{
    width: 370px;
    min-height: 370px;
}

.modeSolo .layer {
    width: 100%;
    height: 240px;
    margin-bottom: 5px;
}

.modeSolo .titre{
    width: 76%;
}

.modeSolo .sousTitre{
    font-size: 12px;
    text-align: center;
}

.modeSolo .numero{
    font-weight:bold;
    text-align: start;
    color: #694523;
    font-size: 40px;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.4em;
}

.modeSolo .itemsContainer{
    display: block;
    margin: 0;
    margin-bottom: 10px;
    margin-top: 10px;

    padding: 0;
    height: 170px;
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
}

.modeSolo .bouton{
    margin-top: 15px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    clear: both;
}

.modeSolo .switch{
    margin-top: 20px;
}

.modeSolo .checkBox{
    margin-top: 20px;
}