.Achievement .titre {
    width: auto;
}

.Achievement .sousTitre {
    text-align: center;
}

.Achievement .dummyItem {
    display: block;

    width: 60px;
    height: 55px;

    border: solid 2px #fff;
    border-radius: 10%;

    box-shadow: -4px 4px 0 rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin: 0px;
    margin-bottom: 8px;
    margin-inline-end: 14px;

    position: relative;

}


.Achievement .nodata {
    display: block;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 470px;
    margin-top: 30px;

}

.Achievement .perso {
    display: block;
    width: 240px;
    height: 130px;
    margin-left: auto;
    margin-right: auto;
    background: center/cover no-repeat url(/public/assets/interfaces/persoMouche.png);
}

.panel.Achievement .store .sousTitre {
    float: none;
}