.report {
    width: 370px;
    min-height: 300px;
}


.report .layer {
    width: 100%;
    height: 220px;
    margin-bottom: 5px;
    margin-top: 5px;

}

.report .titre {
    width: 76%;
}

.report .sousTitre {
    font-size: 12px;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 10px;
}


.report .bouton {
    margin-top: 10px;
    margin-bottom: 10px;

    width: 200px;
    margin-left: auto;
    margin-right: auto;
    clear: both;
}


.report i.icon-ok {
    inset-inline-end: 35px;
    width: 1em;
    inset-inline-start: unset;
    color: rgb(0, 200, 0);

}

.report i.icon-cancel,
.report i.icon-notavailable {
    inset-inline-end: 35px;
    width: 1em;
    inset-inline-start: unset;
    color: rgb(200, 0, 0);
}

.report i {
    display: inline;
    padding: 10px;

    padding-top: 8px;

    color: #694523;
    text-align: center;
    position: absolute;
    inset-inline-start: 10px;
}

.report select {
    text-indent: 10px;
    width: 90%;
}

.report .checkBox {
    margin-top: 10px;

}

/*
.report .checkBox {
    width: 200px;
}


.report form {
    width: 200px;
    margin-right: auto;
    margin-left: auto;
    overflow: hidden;
}*/