.buy {
  position: absolute;
  width: 400px;
}

.buy .description {
  margin-bottom: 10px;
}

.buy .perso {
  display: block;
  width: 180px;
  height: 280px;
  background: center/cover no-repeat url(/public/assets/interfaces/perso.png);
  float: left;
  margin-inline-start: -100px;
}

.buy .perso:dir(rtl) {
  transform: scaleX(-100%);
  float: right;

}

.buy .layer {
  width: 275px;
  float: right;
}

.buy .layer:dir(rtl) {
  float: left;
}

.buy span.itemStore {
  display: block;

  width: 100%;
  height: 175px;

  border: solid 2px #fff;
  border-radius: 10px;
  background: linear-gradient(rgba(256, 256, 256, 0.8), rgba(256, 256, 256, 0.2));
  ;

  box-shadow: -4px 4px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin: 0px;


  position: relative;

}

.buy .indicateur {
  margin-top: 20px;
  transform: scale(130%);
  margin-inline-end: 40px;
}

.buy .bouton {
  position: absolute;
  bottom: -40px;
  left: 20%;
}

.buy .itemStore .titre {
  width: 100%;
  text-align: center;
  margin-bottom: -20px;
  z-index: 1;
  position: relative;
}

.buy .priceTag {
  height: 23px;

  padding: 0;
  position: relative;

  text-align: center;
  text-indent: 10px;
  display: block;
  line-height: 1.5em;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.4);
  color: #fff;
  text-decoration: none;
  font-size: 11pt;
  font-weight: bold;

  border-radius: 5px;

  cursor: pointer;

  position: absolute;
  bottom: 5px;
  width: 90px;
  background: linear-gradient(#86ccbb 30%, #5a9f93);

  border: 1px solid #41645d;
  border-top: 1px solid #fff;
  box-shadow: -1px 2px 0px rgba(0, 0, 0, 0.5),
    inset 0px -2px 0 #41645d;



  inset-inline-end: 10px;

}

.buy .priceTag .iconCoin {
  display: block;
  position: absolute;
  top: -2px;
  inset-inline-start: -2px;
  width: 25px;
  height: 45px;
  background: center/cover no-repeat url(/public/assets/icons/Coin.png);
  z-index: 2;
}


.buy .buck .priceTag {

  background: linear-gradient(#5FC86C 30%, #62955D);

  border: 1px solid #426932;
  border-top: 1px solid #fff;
  box-shadow: -1px 2px 0px rgba(0, 0, 0, 0.5),
    inset 0px -2px 0 #426932;


}

.buy .buck .priceTag .iconCoin {
  width: 45px;
  height: 32px;
  top: -3px;
  inset-inline-start: -17px;
  background: center/cover no-repeat url(/public/assets/icons/pileOfMoney.png);
}


.buy .shine {
  background: center / contain no-repeat url(/public/assets/interfaces/shine.png);
  display: block;
  width: 400px;
  height: 400px;
  position: absolute;
  animation-iteration-count: infinite;
  animation-duration: 10s;
  animation-name: shining;
  animation-timing-function: linear;
  z-index: -1;
  top: -60px;
  left: -60px;
  pointer-events: none;
}