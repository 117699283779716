/*
@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@800&family=Noto+Kufi+Arabic:wght@300;400;700;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,900;1,100;1,300;1,400&display=swap');
*/

@font-face {
  font-family: 'Cooper';
  src: url(/public/assets/fonts/cooper_black_regular-webfont.woff2) format('woff2'),
    url(/public/assets/fonts/cooper_black_regular-webfont.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'icon';
  src: url(/public/assets/fonts/icon.woff2?19321497) format('woff2'),
    url(/public/assets/fonts/icon.woff?19321497) format('woff');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/public/assets/fonts/Almarai.woff2) format('woff2');
}

@font-face {
  font-family: 'Noto Kufi Arabic';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/public/assets/fonts/Noto_Kufi_Arabic_300.woff2) format('woff2');
}

@font-face {
  font-family: 'Noto Kufi Arabic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/public/assets/fonts/Noto_Kufi_Arabic_400.woff2) format('woff2');
}

@font-face {
  font-family: 'Noto Kufi Arabic';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/public/assets/fonts/Noto_Kufi_Arabic_700.woff2) format('woff2');
}

@font-face {
  font-family: 'Noto Kufi Arabic';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/public/assets/fonts/Noto_Kufi_Arabic_900.woff2) format('woff2');
}












@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(/public/assets/fonts/Roboto-ThinItalic.woff2) format('woff2');

}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/public/assets/fonts/Roboto-Italic.woff2) format('woff2');
}


@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/public/assets/fonts/Roboto-BoldItalic.woff2) format('woff2');
}


@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(/public/assets/fonts/Roboto-BlackItalic.woff2) format('woff2');
}


@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/public/assets/fonts/Roboto-Thin.woff2) format('woff2');
}


@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/public/assets/fonts/Roboto-Regular.woff2) format('woff2');

}


@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/public/assets/fonts/Roboto-Bold.woff2) format('woff2');
}


@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/public/assets/fonts/Roboto-Black.woff2) format('woff2');
}

























body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body:dir(rtl) {
  font-family: 'Noto Kufi Arabic', sans-serif !important;
  line-height: 1.2;
  /*unicode-bidi: bidi-override;*/
}

.bouton:dir(rtl),
.checkBox:dir(rtl),
.titre:dir(rtl),
.txt_type:dir(rtl) {
  font-family: 'Almarai', sans-serif;
  /*unicode-bidi: bidi-override;*/
}

.fleche:dir(rtl).left {
  transform: scaleX(1);
}

.fleche:dir(rtl) {
  transform: scaleX(-1);
}

.fleche:dir(rtl):before {
  top: 15%;
  inset-inline-start: 30%;
}

.checkBox .knob:dir(rtl) {
  inset-inline-start: 12px;
}





/*
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,900;1,400&display=swap');
    font-family: 'Jomhuria', cursive;
    font-family: 'Noto Kufi Arabic', sans-serif;
    font-family: 'Roboto', sans-serif;
*/

:root {
  --oval-w: 30%;
  --oval-h: 30%;
  --base-color: rgba(241, 230, 186, 1);
  --base-color-trans: rgba(241, 230, 186, 0.8);



  --pattern: url(/public/assets/interfaces/fondMosaique.png);

  --patternHeader: url(/public/assets/interfaces/fondMosaique-header.png);

  --base-color-header: rgba(235, 221, 139, 1);
  --base-color-header-trans: rgba(229, 208, 123, 0.8);
}


html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}

canvas {
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}


@media only screen and (orientation:portrait) {

  /* Styles spécifiques à l'affichage lorsque l'écran n'est pas en mode paysage */
  /* Placez ici vos styles spécifiques pour cet affichage */
  /* Par exemple : */
  #root {
    visibility: hidden;
  }

  #orientation {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: center/contain no-repeat url(/public/assets/interfaces/orientation.gif) #000;
  }

}