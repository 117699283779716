.connexion {
    display: block;
}

.connexion .titre {
    width: 70%;
}

.connexion .layer {
    clear: right;

    width: 48%;
    float: right;
    height: 270px;
}

.connexion .layer:dir(rtl) {
    float: left;
    clear: left;

}

.connexion .layer.first {
    float: left;
}

.connexion .layer.first:dir(rtl) {
    float: right;
}

.connexion .bouton {
    margin-left: auto;
    margin-right: auto;
    width: 80%;

}

.connexion .message {
    display: block;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
}

.connexion .message.title {
    height: 30px;
}

.connexion .bouton.green {
    margin-top: 5px;
}

.connexion i.icon-ok {
    inset-inline-end: 35px;
    width: 1em;
    inset-inline-start: unset;
    color: rgb(0, 200, 0);

}

.connexion i.icon-cancel,
.connexion i.icon-notavailable {
    inset-inline-end: 35px;
    width: 1em;
    inset-inline-start: unset;
    color: rgb(200, 0, 0);
}

.connexion i {
    display: inline;
    padding: 10px;
    color: #694523;
    text-align: center;
    position: absolute;
    inset-inline-start: 24px;
}

.connexion input {
    text-indent: 30px;
}

.connexion .googleButton {
    display: block;
    margin-right: auto;
    margin-left: auto;
    width: 215px;
    height: 40px;
    overflow: hidden;
}

.connexion .boutonFacebook {
    display: block;
    width: 180px;
    height: 40px;
    background: 8px 8px no-repeat url("../../../public/assets/icons/facebook.svg") #4267b2;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10px;
    border-radius: 3px;
    color: #fff;
    border: solid 1px #1a3d84;

    padding-left: 30px;

    text-align: center;
    font-weight: bold;
    font-size: 13px;
    line-height: 3em;
    cursor: pointer;
    overflow: hidden;

}

.connexion .boutonFacebook:hover {
    background-color: rgb(39, 76, 150);

}

.separateur {
    margin-top: 23px;
    margin-bottom: 23px;

}