.green #green-shape-gradient {
  --color-bot: #56978D;
  --color-stop: #6FC4AF;
}

.green #green-shape-invert-gradient {
  --color-invert-stop: #56978D;
  --color-invert-bot: #6FC4AF;
}

.green #green-storke-gradient {
  --color-storke-stop: #fff;
  --color-storke-bot: #41645D;
}



.fleche.green .triangle {
  fill: url(#green-shape-gradient);
  stroke: url(#green-storke-gradient);
  stroke-width: 2px;
}

.fleche.green .triangle:hover {
  fill: url(#green-shape-invert-gradient);
}

.fleche.green .triangle2 {
  fill: #41645D;
  filter: drop-shadow(10px 10px 0px rgba(0, 0, 0, 0.3));
}





.bleue #bleue-shape-gradient {
  --color-stop: #88C5E5;
  --color-bot: #077BBB;
}

.bleue #bleue-shape-invert-gradient {
  --color-invert-stop: #077BBB;
  --color-invert-bot: #88C5E5;
}

.bleue #bleue-storke-gradient {
  --color-storke-stop: #fff;
  --color-storke-bot: #196589;
}

.fleche.bleue .triangle {
  fill: url(#bleue-shape-gradient);
  stroke: url(#bleue-storke-gradient);
  stroke-width: 2px;
}

.fleche.bleue .triangle:hover {
  fill: url(#bleue-shape-invert-gradient);

}

.fleche.bleue .triangle2 {
  fill: #196589;
  filter: drop-shadow(10px 10px 0px rgba(0, 0, 0, 0.3));
}

.fleche::before {
  /* content:url(/public/assets/interfaces/motif-bouton-small.png);*/
  position: absolute;
  top: 7px;
  inset-inline-start: 7px;
  pointer-events: none;
}

.fleche.left {
  transform: scaleX(-1);
}


.fleche {
  display: block;
  position: relative;
  width: 40px;
  height: 40px;
  /*background-image: url(/public/assets/interfaces/motif-bouton-right.png) left no-repeat;*/
  margin: 0;
  cursor: pointer;
}

.fleche .mosaic {
  fill: rgba(255, 255, 255, 0.7);
  pointer-events: none;
  transform: translate(30px, 55px) scale(70%);
}