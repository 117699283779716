.modal {
    bottom: 25px;
    display: block;
    position: fixed;
    top: 10px;
    width: max-content;
    height: max-content;

    pointer-events: auto;
    margin: 0;

    border-radius: 7px;
    background: linear-gradient(#F3E8B5 20%, #DDC37F 80%);
    border: 1px solid #A59655;
    border-top: 1px solid #FCE8D0;
    box-shadow: -4px 4px 0px rgba(0, 0, 0, 0.1),
        inset 0px -2px 0 #A59655;


    padding-top: 10px;
    padding-inline-end: 30px;
    padding-bottom: 15px;
    padding-inline-start: 10px;



    z-index: 20;

    pointer-events: auto;


    font-size: 15px;
    font-weight: bold;
    color: #fff;
    text-shadow: 0 1px 1px #000;
    text-align: start;

}

.modal.green {
    border: 1px solid rgb(0, 116, 0);
    border-top: 1px solid #FCE8D0;
    background: linear-gradient(rgb(0, 200, 0) 20%, rgb(0, 220, 0) 80%);
    box-shadow: -4px 4px 0px rgba(0, 0, 0, 0.1),
        inset 0px -2px 0 rgb(0, 116, 0);
}

.modal.red {
    border: 1px solid rgb(116, 0, 0);
    border-top: 1px solid #FCE8D0;
    background: linear-gradient(rgb(200, 0, 0) 20%, rgb(220, 0, 0) 80%);
    box-shadow: -4px 4px 0px rgba(0, 0, 0, 0.1),
        inset 0px -2px 0 rgb(116, 0, 0);
}

.modal .bouton-icon {
    position: absolute;
    inset-inline-end: 3px;
    top: 10px;
}