.itemGameMenu {
    display: block;

    width: 528px;
    height: 205px;


    background: linear-gradient(#FEF9E6, #FEEFC4);
    border-radius: 7px;

    border: solid 1px #E7D790;
    box-shadow: 0px 2px #A59655;
    overflow: hidden;
}



.itemGameMenu img {
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
    position: absolute;
    top: 0;
    z-index: 1;
    left: 0;
}

.itemGameMenu.locked img {
    filter: saturate(0);
}

.itemGameMenu .imageContainer {
    display: block;
    margin: 7px;
    height: 190px;
    width: 250px;
    background: linear-gradient(rgba(66, 66, 66, 0.0) 50%, rgba(66, 66, 66, 0.1)) 100% 100% no-repeat;
    border-radius: 7px;
    overflow: hidden;
    position: relative;
    float: left;

}

.itemGameMenu .imageContainer:dir(rtl) {
    float: right;
}

.itemGameMenu .boutonsMenu:dir(rtl),
.itemGameMenu .unLockGame:dir(rtl) {
    float: left;
}

.itemGameMenu .refrac {
    display: block;
    height: 50%;
    width: 38%;
    position: absolute;
    top: 0;
    background: center/cover no-repeat url(/public/assets/interfaces/refractionEffect.png);
    z-index: 2;
    left: 0;
}


.itemGameMenu .back {
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 0;
    left: 0;
}

.itemGameMenu.locked .refrac {
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;

    background: center/cover no-repeat url(/public/assets/icons/GameLocked.png);
    z-index: 2;
}


.itemGameMenu .imageContainer .bouton-icone {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    z-index: 4;
}

.itemGameMenu .boutonsMenu,
.itemGameMenu .unLockGame {
    display: block;
    float: right;
    width: 255px;
    height: 190px;
    margin: 7px;
    margin-inline-start: 0;
    position: relative;
}

.boutonsMenu .boutonMenu {
    display: block;
    width: 120px;
    height: 90px;
    float: left;
    margin-inline-end: 7px;
    margin-bottom: 7px;
    position: relative;
}

.boutonMenu .bouton-icone {
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    z-index: 2;
    margin: 0;
}

.boutonMenu .play {

    clip-path: polygon(0% 100%, 0 39%, 3% 36%, 26% 36%, 28% 33%, 28% 3%, 31% 0, 100% 0%, 100% 100%);

    display: block;
    height: 90px;
    width: 120px;

    cursor: pointer;
    padding: 0;
    position: relative;


    text-align: center;
    text-indent: 0;
    text-decoration: none;
    font-size: 12px;
    font-weight: 600;

    margin: 0px;

    border-radius: 5px;

    float: right;

    line-height: 13em;
    color: #fff;
    text-shadow: -1px 1px 1px rgba(0, 0, 0, 0.4);
    font-variant: normal;

    background: linear-gradient(#F3E8B5 20%, #DDC37F 80%);
    border: 1px solid #A59655;
    border-top: 1px solid #FCE8D0;
    box-shadow:
        inset 0px -2px 0 #A59655;
}

.boutonMenu .play:dir(rtl) {
    transform: scaleX(-1);
}

.boutonMenu .text:dir(rtl) {
    transform: scaleX(-1);
}

.boutonMenu .text {
    display: block;
}

.boutonMenu .red {
    background: linear-gradient(#AF2027, #F3304B 30%, #AF2027);
    border: 1px solid #7C0E11;
    border-top: 1px solid #fff;
    box-shadow:
        inset 0px -2px 0 #7C0E11;
}

.boutonMenu .red:hover {
    background: linear-gradient(#F3304B, #AF2027 30%, #F3304B);
}




.boutonMenu .yellow {
    background: linear-gradient(#dabc4e, #fdf5a7 30%, #dabc4e);
    border: 1px solid #988D13;
    border-top: 1px solid #fff;
    box-shadow:
        inset 0px -2px 0 #988D13;
}

.boutonMenu .yellow:hover,
.boutonMenu .yellow:active {
    background: linear-gradient(#fdf5a7, #dabc4e 30%, #fdf5a7);
}





.boutonMenu .bleu-green {
    background: linear-gradient(#4B8C81, #6DC1AD 30%, #4B8C81);

    border: 1px solid #42675D;
    border-top: 1px solid #fff;
    box-shadow:
        inset 0px -2px 0 #42675D;
}

.boutonMenu .bleu-green:hover,
.boutonMenu .bleu-green:active {
    background: linear-gradient(#6DC1AD, #4B8C81 30%, #6DC1AD);

}




.boutonMenu .bleu {
    background: linear-gradient(#077BBB, #88C5E5 30%, #077BBB);
    border: 1px solid #196589;
    box-shadow:
        inset 0px -2px 0 #196589;
    border-top: 1px solid #fff;
}

.boutonMenu .bleu:hover,
.boutonMenu .bleu:active {
    background: linear-gradient(#88C5E5, #077BBB 30%, #88C5E5);
}

.boutonMenu .green {
    background: linear-gradient(#62955D, #5FC86C 30%, #62955D);

    border: 1px solid #426932;
    border-top: 1px solid #fff;
    box-shadow:
        inset 0px -2px 0 #426932;
}

.boutonMenu .green:hover,
.boutonMenu .green:active {
    background: linear-gradient(#5FC86C, #62955D 30%, #5FC86C);
}



.boutonMenu .vsOnlinePlayers {
    display: block;
    width: 100%;
    height: 80%;
    background: center/cover no-repeat url(/public/assets/icons/GameMenu01.png);
}

.boutonMenu .vsOnlinePlayer {
    display: block;
    width: 100%;
    height: 80%;
    background: center/cover no-repeat url(/public/assets/icons/GameMenu02.png);

}

.boutonMenu .vsFriends {
    display: block;
    width: 100%;
    height: 80%;
    background: center/cover no-repeat url(/public/assets/icons/GameMenu03.png);

}

.boutonMenu .vsbots {
    display: block;
    width: 100%;
    height: 80%;
    background: center/cover no-repeat url(/public/assets/icons/GameMenu04.png);

}


.unLockGame .perso {
    display: block;
    width: 120px;
    height: 200px;
    background: center/cover no-repeat url(/public/assets/interfaces/perso.png);
    float: left;
    position: absolute;
    inset-inline-start: -40px;
    z-index: 3;

}

.unLockGame .perso:dir(rtl) {
    transform: scaleX(-100%);
    float: right;

}

.unLockGame .calque:dir(rtl) {
    float: left;
}


.unLockGame .calque {
    position: relative;
    margin-top: 30px;
    margin-inline-end: 18px;
    height: 135px;
    width: 170px;
    float: right;
    background: #fff;

}

.unLockGame .sousTitre {
    text-align: center;
}



.unLockGame .priceTag {
    height: 23px;

    padding: 0;
    position: relative;

    text-align: center;
    text-indent: 25px;
    display: block;
    line-height: 1.5em;
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.4);
    color: #fff;
    text-decoration: none;
    font-size: 11pt;
    font-weight: bold;

    border-radius: 5px;


    width: 90px;
    background: linear-gradient(#5FC86C 30%, #62955D);

    border: 1px solid #426932;
    border-top: 1px solid #fff;
    box-shadow: -1px 2px 0px rgba(0, 0, 0, 0.5),
        inset 0px -2px 0 #426932;


    margin: 10px;
    margin-left: auto;
    margin-right: auto;

    pointer-events: none;

    /*
    inset-inline-end: 10px;
    position: absolute;
    bottom: 5px;
*/
}

.unLockGame .priceTag .iconBuck {
    display: block;
    position: absolute;
    width: 45px;
    height: 32px;
    top: -3px;
    inset-inline-start: -17px;
    background: center/cover no-repeat url(/public/assets/icons/pileOfMoney.png);
    z-index: 2;

}

.unLockGame .bouton {
    margin-left: auto;
    margin-right: auto;
    pointer-events: auto;
    width: 162px;
    margin-bottom: 5px;
}


.unLockGame .bouton.desactivate {
    filter: saturate(0%);
    pointer-events: none;
}