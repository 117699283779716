.modeVersus {
    width: 370px;
    min-height: 370px;
}

.modeVersus .layer {
    width: 100%;
    height: 240px;
    margin-bottom: 5px;
}

.modeVersus .titre {
    width: 76%;
    font-size: 20pt;
}

.modeVersus .sousTitre {
    font-size: 12px;
    text-align: center;
}

.modeVersus .numero {
    font-weight: bold;
    text-align: start;
    color: #694523;
    font-size: 40px;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.4em;
}

.modeVersus .itemsContainer {
    display: block;
    margin: 0;
    margin-bottom: 10px;
    margin-top: 10px;

    padding: 0;
    height: 170px;
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
}

.modeVersus .bouton {
    margin-top: 15px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    clear: both;
}

.modeVersus .switch {
    margin-top: 20px;
}

.modeVersus .checkBox {
    margin-top: 20px;
}

.modeVersus .bouton.desactivate {
    filter: saturate(0%);
    pointer-events: none;
}