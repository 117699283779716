span.itemAchievement {
    display: block;
    
    width: 60px;
    height: 55px;

    border: solid 2px #fff;
    border-radius: 10%;
    background: linear-gradient(rgba(256,256, 256, 0.4), rgba(256,256, 256, 0.1)); ;
    
    box-shadow: -4px 4px 0 rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin: 0px;
    margin-bottom: 8px;
    margin-inline-end: 14px;

    position: relative;
    
}


.itemAchievement img{
    width: 90%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
}