.panel.log {
  width: 450px;
  height: 350px;
}

.panel.log .layer {
  height: 270px;

}

.panel.log i {
  display: inline;
  float: none;
  color: #FFE015;
}

.panel.log .cardnumber {
  color: #FFE015
}


.panel.log .black {
  margin: 0;
  height: 230px;
  background-color: rgba(20, 20, 20, 1);
  border-radius: 10px;
  font-size: 12px;
  overflow: hidden;
  overflow-y: scroll;
  text-align: initial;
  line-height: 2em;
  padding: 10px;
  color: #BBB;
}

.panel.log .black ol {
  display: block;
  margin: 0;
  padding: 0;
  padding-inline-end: 10px;
  padding-inline-start: 20px;

}

.panel.log ol li span {
  float: right;
}


.panel.log ol li span:dir(rtl) {
  float: left;
}