.tooltip {
    bottom: 25px;
    display: block;
    position: relative;
    width: max-content;
    pointer-events: auto;
    margin: 0;

    border-radius: 7px;
    background: linear-gradient(#F3E8B5 20%, #DDC37F 80%);
    border: 1px solid #A59655;
    border-top: 1px solid #FCE8D0;
    box-shadow: -4px 4px 0px rgba(0, 0, 0, 0.1),
        inset 0px -2px 0 #A59655;

    padding: 0 10px 5px 10px;
    z-index: 30;

    transform: translateX(-42%) translateY(-100%);
    pointer-events: auto;
}


.tooltip:dir(rtl) {
    transform: translateX(42%) translateY(-100%);

}

.tooltip.ok {
    border: 1px solid rgb(0, 116, 0);
    border-top: 1px solid #FCE8D0;
    background: linear-gradient(rgb(0, 200, 0) 20%, rgb(0, 220, 0) 80%);
    box-shadow: -4px 4px 0px rgba(0, 0, 0, 0.1),
        inset 0px -2px 0 rgb(0, 116, 0);
}

.tooltip.ok .triangle {
    border-top: 10px solid rgb(0, 220, 0);

}

.tooltip.cancel,
.tooltip.notavailable {
    border: 1px solid rgb(116, 0, 0);
    border-top: 1px solid #FCE8D0;
    background: linear-gradient(rgb(200, 0, 0) 20%, rgb(220, 0, 0) 80%);
    box-shadow: -4px 4px 0px rgba(0, 0, 0, 0.1),
        inset 0px -2px 0 rgb(116, 0, 0);
}

.tooltip.cancel .triangle,
.tooltip.notavailable .triangle {
    border-top: 10px solid rgb(220, 0, 0);

}



.tooltip .titre {
    font-size: 14pt;
    position: absolute;
    top: -15px;
    width: 90%;
    line-height: 0.7em;
    text-align: center;
    z-index: 2;
}

.tooltip .triangle {
    display: block;
    height: 0;
    width: 0;

    margin-bottom: -5px;


    border-inline-end: 5px solid transparent;
    border-inline-start: 5px solid transparent;
    border-top: 10px solid #DDC37F;
    transform: translateX(-5px);

    margin-left: auto;
    margin-right: auto;
    position: absolute;
    bottom: 0;
    inset-inline-start: 50%;
}

.tooltip .triangle::before {
    display: block;
    position: absolute;

    margin-bottom: -8px;
    transform: rotate(90deg) translateX(-5px);


    border-inline-end: 10px solid transparent;
    border-inline-start: 10px solid transparent;
    border-top: 20px solid #A59655;
}

.tooltip .triangle:dir(rtl) {
    transform: translateX(5px);

}



.tooltip .tooltipContainer {
    position: relative;
    display: block;
    width: 100%;
    height: 80%;

    margin-left: auto;
    margin-right: auto;
}

.tooltip .tooltipContainer span.message {
    display: block;
    font-size: 11px;
    font-weight: bold;
    color: #fff;
    text-shadow: 0 1px 1px #000;
    text-align: start;
    margin-bottom: 5px;
    line-height: 1em;
    width: 100%;
    height: fit-content;
    float: none;
}