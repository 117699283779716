.smileyToolTip.small {
    transform: translateX(-40%);
    width: 160px;
    height: 90px;
}

.smileyToolTip.big {
    transform: translateX(-46%);
    width: 310px;
    height: 260px;
}

.smileyToolTip.medium {
    transform: translateX(-45%);
    width: 250px;
    height: 170px;
}

.smileyToolTip.big .smileyContainer {
    height: 96%;
}

.smileyToolTip {

    bottom: 27px;
    display: block;
    position: absolute;
    transform: translateX(-44%);
    width: 220px;
    height: 140px;
    pointer-events: auto;
    margin: 0;

    border-radius: 7px;
    background: linear-gradient(#F3E8B5 20%, #DDC37F 80%);
    border: 1px solid #A59655;
    border-top: 1px solid #FCE8D0;
    box-shadow: -4px 4px 0px rgba(0, 0, 0, 0.1),
        inset 0px -2px 0 #A59655;

    padding: 10px;
    z-index: 10;

}

.smileyToolTip:dir(rtl) {
    transform: translateX(+44%);
}

.smileyToolTip span.titre {
    font-size: 14pt;
    position: absolute;
    top: -15px;
    width: 90%;
    text-align: center;
    z-index: 2;
}

.smileyToolTip .triangle {
    display: block;
    height: 0;
    width: 0;

    margin-bottom: -5px;


    border-inline-end: 5px solid transparent;
    border-inline-start: 5px solid transparent;
    border-top: 10px solid #DDC37F;
    transform: translateX(-5px);

    margin-left: auto;
    margin-right: auto;
    position: absolute;
    bottom: 0;
    inset-inline-start: 50%;
}

.smileyToolTip .triangle::before {
    display: block;
    position: absolute;

    margin-bottom: -8px;
    transform: rotate(90deg) translateX(-5px);


    border-inline-end: 10px solid transparent;
    border-inline-start: 10px solid transparent;
    border-top: 20px solid #A59655;
}

.smileyToolTip .triangle:dir(rtl) {
    transform: translateX(5px);

}

.smileyToolTip .triangleMosaique {
    display: block;
    height: 30px;
    width: 30px;
    position: absolute;
    bottom: 0;
    inset-inline-start: 50%;
    transform: rotate(90deg) translateY(50%);
    margin-left: auto;
    margin-right: auto;
    background: url(/public/assets/interfaces/motif-bouton-left-brun.png) left no-repeat;
}

.smileyToolTip .triangleMosaique:dir(rtl) {
    transform: rotate(90deg) translateY(-50%);

}




.smileyToolTip .smileyContainer {
    position: relative;
    display: block;
    width: 100%;
    height: 90%;

    margin-left: auto;
    margin-right: auto;

    border: solid 2px #fff;
    border-radius: 5px;
    background: linear-gradient(#e7dbc2 30%, #fff);
    box-shadow: 0 0 0 3px #e5d796;

}

.smileyToolTip .toogleStyle {
    float: right;
    margin: 2px;

}

.smileyToolTip .toogleStyle:dir(rtl) {
    float: left;
}


.smileyToolTip .element {
    display: block;
    width: 25px;
    height: 25px;
    padding: 0;
    margin: 0;
    position: relative;
    filter: drop-shadow(2px 2px 1px rgb(0, 0, 0, 0.2));
    float: right;
    margin: 3px;
    cursor: pointer;
}