.toogleLanguage {
    display: block;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
    float: left;
    width: 28px;
    height: 28px;
    position: relative;

}

.toogleLanguage:dir(rtl) {
    float: right;

}

.bouton-icone.language {
    overflow: hidden;

    margin: 0;

    background: linear-gradient(#F3E8B5 20%, #DDC37F 80%);
    border: 1px solid #A59655;
    border-top: 1px solid #FCE8D0;
    /* 
    background: linear-gradient(rgba(255, 255, 255, 0.7) 30%, rgba(255, 255, 255, 0.1));
    border: 1px solid #4f4f4f; */
    box-shadow: -1px 2px 0px rgba(0, 0, 0, 0.3),
        inset 0px -2px 0 #A59655;
    border-top: 1px solid #fff;
}

.bouton-icone.language span:hover {
    background: linear-gradient(rgba(255, 255, 255, 0.4) 10%, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0.5));
}

.bouton-icone.language span {
    background: linear-gradient(rgba(255, 255, 255, 0.3) 10%, rgba(255, 255, 255, 0.7) 30%, rgba(255, 255, 255, 0));
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    z-index: 1;
}

.bouton-icone.language img {
    width: 100%;
    height: 100%;
}