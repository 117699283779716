.basDePage {
    display: block;
    pointer-events: auto;


    position: absolute;
    bottom: 0;
    width: 680px;
    height: 70px;

    background: linear-gradient(var(--base-color-header-trans), var(--base-color-header)),
        linear-gradient(transparent, #E5D07B 75%) center/90% 100% no-repeat,
        var(--pattern) center/100px repeat,
        linear-gradient(var(--base-color-trans), var(--base-color));


    border-radius: 70% / 25%;

    margin-left: auto;
    margin-right: auto;

    border: #D3BF72 solid 7px;
    border-bottom: none;

    border-inline-start: none;
    border-inline-end: none;
    box-shadow: 0px 0px 0px 2px #f7e9a8,
        0px 0px 10px 10px rgba(0, 0, 0, 0.3);

    z-index: 2;


}


.basDePage:after {
    content: no-close-quote;
    display: block;

    position: absolute;
    top: 0;
    bottom: 0;
    inset-inline-end: -15px;
    inset-inline-start: -15px;
    background: inherit;
    border: inherit;
    border-radius: 15px 15px 0 0;

    border-top: none;

    border-bottom: none;


    border: #D3BF72 solid 6px;
    border-top: 0;
    border-bottom: 0;
}

.basDePageContainer {
    display: block;
    position: relative;
    z-index: 1;
    padding: 7px 0px 5px 0px;
}

.basDePageContainer .fleche {
    float: left;
    margin-top: 8px;
}

.basDePageContainer .fleche:dir(rtl) {
    float: right;

}

.basDePageContainer .bouton {
    float: right
}

.basDePageContainer .bouton:dir(rtl) {
    float: left;
}


.basDePageContainer .gold {
    clear: right;
    float: right;
    margin-top: 5px;
}

.basDePageContainer .gold:dir(rtl) {
    clear: left;
    float: left;
}



.basDePage .friendsConter {
    display: block;
    width: 65%;
    height: 65px;
    float: left;
    margin-top: -5px;
    overflow: hidden;

    overflow-x: scroll;

}

.basDePage .friendsConter:dir(rtl) {
    float: right;

}

.basDePage .friendsContainer {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: fit-content;
    height: auto;
}