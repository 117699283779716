.panelreward {
    width: 400px;
    min-height: 350px;
}


.panelreward .layer {
    width: 320px;
    height: 250px;
    margin-bottom: 5px;
    margin-top: 10px;
    position: relative;
    float: left;

}

.panelreward .layer:dir(rtl) {
    float: right;
}

.panelreward .titre {
    width: 76%;
}

.panelreward .sousTitre {
    font-size: 12px;
    text-align: center;
    margin-bottom: 5px;
    margin-top: 5px;
}


.panelreward .bouton {
    margin-top: 10px;
    margin-bottom: 10px;

    width: 200px;
    margin-left: auto;
    margin-right: auto;
    clear: both;
}

.panelreward .unlockedAchievement {
    display: block;
    width: 200px;
    height: 200px;
}


.panelreward .unlockedAchievement img {
    display: block;
    width: 100px;
    height: 100px;
    float: left;
    margin-left: 40px;
    margin-right: 40px;
    z-index: 3;

}

.panelreward .description {
    display: block;
    text-align: center;
    float: left;
    height: 33px;

    width: 160px;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
}

.panelreward .perso {
    display: block;
    width: 120px;
    height: 280px;
    background: center/cover no-repeat url(/public/assets/interfaces/persoMan.png);
    float: right;
    margin-inline-end: -90px;
}

.panelreward .perso:dir(rtl) {
    float: left;
}

.panelreward .perso:dir(rtl) {
    transform: scaleX(-100%);
}


.panelreward .shine {
    background: center / contain no-repeat url(/public/assets/interfaces/shine.png);
    display: block;
    width: 600px;
    height: 600px;
    position: absolute;
    animation-iteration-count: infinite;
    animation-duration: 10s;
    animation-name: shining;
    animation-timing-function: linear;
    z-index: -1;
    top: -200px;
    left: -140px;
    pointer-events: none;
}




.panelreward .bouton-reward {
    height: 23px;

    padding: 0;
    position: relative;

    text-align: center;
    text-indent: 10px;
    display: block;
    line-height: 1.5em;
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.4);
    color: #fff;
    text-decoration: none;
    font-size: 11pt;
    font-weight: bold;

    border-radius: 5px;

    float: left;


    width: 100px;
    background: linear-gradient(#5FC86C 30%, #62955D);

    border: 1px solid #426932;
    border-top: 1px solid #fff;
    box-shadow: -1px 2px 0px rgba(0, 0, 0, 0.5),
        inset 0px -2px 0 #426932;

    margin-left: 40px;
    margin-right: 40px;
    margin-top: 5px;
    /* margin-top: 5px; */

    /* inset-inline-end: 50%;
    transform: translateX(-50%);
*/
}

.panelreward .bouton-reward {
    background: linear-gradient(#86ccbb 30%, #5a9f93);

    border: 1px solid #41645d;
    border-top: 1px solid #fff;
    box-shadow: -1px 2px 0px rgba(0, 0, 0, 0.5),
        inset 0px -2px 0 #41645d;
}

.panelreward .bouton-reward .iconCoin {
    display: block;
    position: absolute;
    top: -2px;
    inset-inline-start: -3px;
    width: 25px;
    height: 45px;
    background: center/cover no-repeat url(/public/assets/icons/Coin.png);
    z-index: 2;
}