.archives {
    width: 700px;
    min-height: 400px;
    position: relative;
}

.panel.log,
.panel.report,
.panel.profil {
    z-index: 1;
}

.archives .layer {
    height: 285px;
}

.archives .titre {
    margin: 0px;
    width: auto;
}

.archives .onglet {
    clear: both;
    list-style-type: none;
    float: left;
    margin: 0;
    padding: 0;
    margin-top: 10px;
    margin-bottom: -4px;
    position: relative;

}


.archives .tableTitle:dir(rtl) li {
    float: right;
}

.archives .onglet:dir(rtl) {
    float: right;
}

.archives .onglet li {
    display: block;
    float: right;
    cursor: pointer;
    font-size: 13px;
    font-weight: bold;
    color: #644629;
    text-shadow: 0 2px 1px #fff;

    /* 
    border-radius: 3%/50%;
    border: solid 2px #fff;
    border-left: solid 2px #cec08a;
    border-bottom: none; 
    background-color: #f7ebc1;
   
    width: 110px; */
    height: 25px;

    line-height: 2em;

    padding: 5px 15px 5px 15px;
    margin-inline-end: -15px;
    position: relative;
    z-index: 0;
    background: center/cover no-repeat url(../../../public/assets/interfaces/onglet-Off.png);
    background-size: 100% 100%;
    white-space: nowrap;
    overflow: hidden;
}

.archives .onglet li:dir(rtl),
.archives .fleche:dir(rtl) {
    float: left;
}


.archives .onglet li:hover,
.archives .onglet li.active {
    background: center/cover no-repeat url(../../../public/assets/interfaces/onglet-On.png);
    background-size: 100% 100%;
    z-index: 3;
}

.archives .layerNone {
    display: none;
}


.archives .rankScroll {
    display: block;
    width: 100%;
    height: 255px;
    margin-top: 5px;
    overflow: hidden;
    scroll-behavior: smooth;

}

.archives .dummyItem {
    display: block;
    width: 100%;
    height: 34px;
    /* background: linear-gradient(#FEF9E6, #FEEFC4);
  
  border: solid 1px #E7D790;
  box-shadow: 0px 2px #A59655; */
    border-radius: 7px;
    padding: 0;
    overflow: hidden;
    float: none;
    margin-bottom: 9px;
}

.archives .fleche {
    width: 25px;
    float: right;
    margin-inline-end: 8px;
}

.archives .tableTitle {
    display: block;
    margin: 0;
    padding: 0;
    height: 10px;

}

.archives .tableTitle li {
    display: block;
    float: left;
    cursor: pointer;
    font-size: 9px;
    font-weight: bold;
    color: #644629;
    text-shadow: 0 2px 1px #fff;
    text-transform: uppercase;
    overflow: hidden;
    width: 80px;

}

.archives .nodata {
    display: block;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 240px;
    margin-top: 30px;

}

.archives .nodata .sousTitre {
    text-align: center;
}

.archives .perso {
    display: block;
    width: 240px;
    height: 120px;
    margin-left: auto;
    margin-right: auto;
    background: center/cover no-repeat url(/public/assets/interfaces/persoMouche.png);
}