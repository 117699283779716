.itemGameConatiner {
    display: block;

    width: 70px;
    height: 50px;

}

.itemGame {
    display: block;

    width: 60px;
    height: 40px;

    border: solid 2px #fff;
    border-radius: 10%;
    background: linear-gradient(rgba(256, 256, 256, 0.4), rgba(256, 256, 256, 0.1));

    box-shadow: -3px 3px 0 rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin: 5px;
    position: relative;

}

.itemGame.selected {
    transform: scale(1.1)
}

.itemGame.cursor {
    border-bottom: solid 2px #e9c92b;
}

.itemGame img {
    height: 100%;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
    border-radius: 10%;
    position: absolute;
    top: 0;
    z-index: 1;
    left: 5px;
}


.itemGame .back {
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 0;
    left: 0;
}


.itemGame .lock {

    left: 10px;
}