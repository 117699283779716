.gift {
  width: 370px;
  min-height: 250px;
}


.gift .layer {
  width: 100%;
  height: 170px;
  margin-bottom: 5px;
  margin-top: 5px;

}

.gift .titre {
  width: 76%;
}

.gift .sousTitre {
  font-size: 12px;
  text-align: center;
  margin-bottom: 15px;
  margin-top: 10px;
}


.gift .bouton {
  margin-top: 15px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  clear: both;
}


.gift i.icon-ok {
  inset-inline-end: 35px;
  width: 1em;
  inset-inline-start: unset;
  color: rgb(0, 200, 0);

}

.gift i.icon-cancel,
.gift i.icon-notavailable {
  inset-inline-end: 35px;
  width: 1em;
  inset-inline-start: unset;
  color: rgb(200, 0, 0);
}

.gift i {
  display: inline;
  padding: 10px;
  color: #694523;
  text-align: center;
  position: absolute;
  inset-inline-start: 24px;
}

.gift input {
  text-indent: 30px;
}


.gift .perso {
  display: block;
  width: 240px;
  height: 120px;
  position: absolute;
  top: -100px;
  right: 50px;
  background: center/cover no-repeat url(/public/assets/interfaces/persoMouche.png);
}



.gift .iconBuck {
  display: block;
  position: absolute;
  top: 5px;
  inset-inline-start: 5px;
  width: 35px;
  height: 35px;
  background: center/cover no-repeat url(../../../public/assets/icons/pileOfMoney.png);
  z-index: 2;

}

.gift .iconCoin {
  display: block;
  position: absolute;
  top: 10px;
  inset-inline-start: 5px;
  width: 40px;
  height: 45px;
  background: center/cover no-repeat url(../../../public/assets/icons/Coin.png);
  z-index: 2;

}

.gift .container {
  display: block;
  margin-bottom: 5px;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.gift .container .calque {
  padding: 20px;
}

.gift .container .reward {

  font-weight: bold;

  font-size: 16pt;
  display: block;
  width: 140px;
  height: auto;
  background: #ffffff;
  position: relative;
  border-radius: 5px;
  margin-top: 3px;

  margin-left: auto;
  margin-right: auto;

  color: #76a715;
  text-shadow: -1px 1px 2px #325201;
  text-indent: 10px;

  border-top: solid 2px #fff;
  border-bottom: solid 2px #D0C082;
  border-inline-end: none;
  border-inline-start: none;
  padding: 10px;
}

.gift .container .reward.coin {
  color: #FCD747;
  text-shadow: -1px 1px 2px #a8813f;

}