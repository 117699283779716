.subscription {
    display: block;
}

.subscription .titre {
    width: 70%;
}

.subscription .layer {
    clear: right;

    width: 48%;
    float: right;
    height: 270px;
}

.subscription .layer:dir(rtl) {
    float: left;
    clear: left;

}

.subscription .layer.first {
    float: left;
}

.subscription .layer.first:dir(rtl) {
    float: right;
}

.subscription .bouton {
    margin-left: auto;
    margin-right: auto;
    width: 80%;

}

.subscription .message {
    display: block;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
}

.subscription .bouton.small {
    margin-top: 5px;
}




.subscription i.icon-ok {
    inset-inline-end: 35px;
    width: 1em;
    inset-inline-start: unset;
    color: rgb(0, 200, 0);

}

.subscription i.icon-cancel,
.subscription i.icon-notavailable {
    inset-inline-end: 35px;
    width: 1em;
    inset-inline-start: unset;
    color: rgb(200, 0, 0);
}

.subscription i {
    display: inline;
    padding: 10px;
    color: #694523;
    text-align: center;
    position: absolute;
    inset-inline-start: 24px;
}

.subscription input {
    text-indent: 30px;
}

.subscription .googleButton {
    display: block;
    margin-right: auto;
    margin-left: auto;
    width: 215px;
    height: 40px;
    overflow: hidden;
}


.subscription .boutonFacebook {
    display: block;
    width: 180px;
    height: 40px;
    background: 8px 8px no-repeat url("../../../public/assets/icons/facebook.svg") #4267b2;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10px;
    border-radius: 3px;
    color: #fff;
    border: solid 1px #1a3d84;

    padding-left: 30px;

    text-align: center;
    font-weight: bold;
    font-size: 13px;
    line-height: 3em;
    cursor: pointer;
    overflow: hidden;
}

.subscription .boutonFacebook:hover {
    background-color: rgb(39, 76, 150);
}

.subscription .conditions {
    color: #694523;
    text-align: center;
    font-size: 11px;
    display: block;
    width: 100%;
    height: auto;
    padding-top: 55px;
}



.subscription.separateur {
    margin-top: 23px;
    margin-bottom: 23px;

}

.subscription .conditions a {
    color: #694523;

    text-decoration: none;
}