.itemArchives {
    display: block;

    width: 100%;
    height: 43px;
    padding: 0;
    margin: 0;
}


.itemArchivesContainer {
    display: block;

    width: 100%;
    height: 34px;
    background: linear-gradient(#FEF9E6, #FEEFC4);
    border-radius: 7px;

    border: solid 1px #E7D790;
    box-shadow: 0px 2px #A59655;
    padding: 0;
    overflow: hidden;
    float: none;

}

.itemArchivesContainer .index {
    display: block;
    margin: 0;
    float: left;
    width: 40px;
    height: 35px;
    border-radius: 7px 0 0 7px;
    color: white;
    text-shadow: 0 0 2px #3c2b15;
    font-size: 14px;
    line-height: 2.2em;
    font-weight: bolder;


    background: linear-gradient(#F3E8B5 20%, #DDC37F 80%);
    border: 1px solid #A59655;
    border-top: 1px solid #FCE8D0;
    box-shadow: inset 0px -2px 0 #A59655;
}


.itemArchivesContainer .vr.right:dir(rtl),
.itemArchivesContainer .Coin:dir(rtl),
.itemArchivesContainer .Buck:dir(rtl) {
    float: left;
}

.itemArchivesContainer .Results:dir(rtl),
.itemArchivesContainer .spacer:dir(rtl),

.itemArchivesContainer .gameImage:dir(rtl),
.itemArchivesContainer .modeType:dir(rtl),
.itemArchivesContainer .modeImage:dir(rtl),
.itemArchivesContainer .index:dir(rtl),
.itemArchivesContainer .imageContainer:dir(rtl) {


    float: right;


}

.itemArchivesContainer .flag:dir(rtl) {
    float: right;
}


.itemArchivesContainer .flag {
    display: inline-block;
    height: 12px;
    width: auto;
    position: absolute;
    bottom: -1px;
    right: -2px;
    border-radius: 2px;

}


.itemArchivesContainer .imageContainer {
    width: 32px;
    height: 32px;
    margin-top: 1px;
    float: left;
    position: relative;
    overflow: hidden;
    border-radius: 6px;
    cursor: pointer;

}

.itemArchivesContainer .image {
    margin: 0;
    width: 32px;
    height: 32px;
}


.itemArchivesContainer .Results {
    display: block;
    height: 100%;
    width: 25px;
    float: left;
    line-height: 2.7em;

    margin-inline-start: 2px;
    font-size: 14px;
    font-weight: 700;
    color: #694523;
}



.itemArchivesContainer .Results.Win {
    color: #62955D;
}

.itemArchivesContainer .Results.Lose {
    color: #AF2027;
}



.itemArchivesContainer .gameImage {
    display: block;
    height: auto;
    width: 60px;
    float: left;
    margin-top: -5px;
    height: 40px;
}

.itemArchivesContainer .modeType,
.itemArchivesContainer .modeImage {
    display: block;
    height: 80%;
    float: left;
}

.itemArchivesContainer .modeImage {
    margin-top: 5px;
}

.itemArchivesContainer .Coin,
.itemArchivesContainer .Buck {
    display: block;
    height: 100%;
    width: 70px;
    float: right;
    line-height: 2.7em;
    text-align: end;
    text-indent: 10px;

    padding-inline-end: 5px;
    font-size: 14px;
    font-weight: 700;
    color: #694523;
}

.itemArchivesContainer .vr.right {
    float: right;
}

.itemArchivesContainer .Buck .ico {
    display: block;
    height: 25px;
    width: 25px;
    margin-top: 5px;
    float: left;
    background: center/cover no-repeat url(/public/assets/icons/pileOfMoney.png);
    margin-inline-end: -10px;
}

.itemArchivesContainer .Coin .ico {
    display: block;
    height: 35px;
    width: 25px;
    margin-top: 5px;
    float: left;
    background: center/cover no-repeat url(/public/assets/icons/Coin.png);
    margin-inline-end: -10px;
}


.itemArchivesContainer .online {
    display: block;
    width: 7px;
    height: 7px;
    border-radius: 10px;
    background-color: #a49453;
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    border: solid 1px #bfae81;
    border-bottom: solid 1px #e7e5de;

}

.itemArchivesContainer .online.on {
    background-color: #15ff00;
    box-shadow: 0 0 5px 3px #15ff00;
}

.itemArchivesContainer .online.off {
    background-color: #a49453;
}



.itemArchivesContainer .modeType.Tournament {
    display: block;
    width: 55px;
    margin-inline-start: -15px;
    height: 100%;
    background: center/cover no-repeat url(/public/assets/icons/GameMenu01.png);
}

.itemArchivesContainer .modeType.Battle {
    display: block;
    width: 55px;
    margin-inline-start: -15px;
    height: 100%;
    background: center/cover no-repeat url(/public/assets/icons/GameMenu02.png);

}

.itemArchivesContainer .modeType.Friendly {
    display: block;
    height: 100%;
    background: center/cover no-repeat url(/public/assets/icons/GameMenu03.png);
    width: 55px;
    margin-inline-start: -15px;
}

.itemArchivesContainer .spacer {
    display: block;
    height: 20px;
    float: right;
    width: 40px
}