.tournament {
    width: 660px;
    min-height: 400px;
}

.tournament .titre {
    width: auto;
    display: none;
}


.tournament .titleIcon {
    display: block;
    float: left;
    width: 160px;
    height: 40px;
    position: relative;
}

.tournament .titleIcon img {
    display: block;
    position: absolute;
    left: -30px;
    top: -30px;
    z-index: 3;
    width: 170px;
}

.tournament .titleIcon .mode {
    display: block;
    position: absolute;
    right: 0;
    bottom: -10px;
    width: 80px;
    display: block;
    height: 40px;
    background: center/cover no-repeat url(/public/assets/icons/GameMenu01.png);
    z-index: 4;

}



.tournament .layer {
    height: 275px;
    float: none;
}

.tournament .containders {
    clear: both;
    display: flex;
    align-content: space-around;
    justify-content: space-around;
}

.tournament .groupPlayer {
    display: block;

    width: 140px;

    border-radius: 7px;


    padding: 5px;
    float: left;

    margin-right: 5px;

}

.tournament .semiFinalist {
    position: absolute;
    top: 0px;
    left: 150px;
    z-index: 10;

    width: 310px;
    height: auto;

    box-shadow: -5px 5px 14px rgba(0, 0, 0, 0.4);

    border: 1px solid #A59655;
    padding: 10px;
    border-radius: 10px;

    background: linear-gradient(#F3E8B5 20%, #DDC37F 80%);

}

.tournament .finalist .groupPlayer {
    position: absolute;
    top: 60px;
    left: 160px;
    z-index: 20;

    width: 280px;
    height: 140px;

    box-shadow: -5px 5px 14px rgba(0, 0, 0, 0.5);

    border: 1px solid #A59655;
    padding: 10px;
}




.tournament .groupPlayer.ready {
    background-color: #FFF;

}


.tournament .groupPlayer .index {
    display: block;
    margin: 0;
    float: left;
    width: 100%;
    height: 24px;
    border-radius: 7px 7px 0 0;
    color: #8C7D3F;
    text-shadow: -2px 2px 0px #FFF4D2;
    font-size: 12px;
    line-height: 2em;
    font-weight: bolder;


    background: linear-gradient(#F3E8B5 20%, #DDC37F 80%);
    border: 1px solid #A59655;
    border-top: 1px solid #FCE8D0;
    box-shadow: inset 0px -2px 0 #A59655;
}

.tournament .groupPlayer .index.selection,
.tournament .groupPlayer .index.start {
    color: white;
    text-shadow: -2px 2px 0px #7C0E11;

    background: linear-gradient(#F3304B 30%, #AF2027);


    border: 1px solid #7C0E11;
    border-top: 1px solid #fff;
    box-shadow: -1px 2px 0px rgba(0, 0, 0, 0.3),
        inset 0px -2px 0 #7C0E11;

}


.tournament .groupPlayer .index.final,
.tournament .groupPlayer .index.end {


    color: #B39415;
    text-shadow: 1px 1px 3px rgba(255, 255, 255, 1);

    background: linear-gradient(#FFF79F 30%, #FFD543);

    border: 1px solid #B39415;
    border-top: 1px solid #fff;
    box-shadow: -1px 2px 0px rgba(0, 0, 0, 0.3),
        inset 0px -2px 0 #B39415;

}

.tournament .tournamentContainer {
    display: block;
    margin-bottom: 5px;
    height: 40px;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
}

.tournament .tournamentContainer img {
    width: auto;
    height: 35px;
    float: left;
    margin-right: 10px;

}

.tournament .bouton {
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    clear: both;
    /* float: right;*/
}

.tournament .bouton.desactivate {
    filter: saturate(0%);
    pointer-events: none;
}

.tournament .tournamentContainer .vs {
    width: 170px;
    height: 30px;
    padding: 3px;
    margin: 0;
    margin-left: auto;
    margin-right: auto;

    font-weight: bold;

    font-size: 22pt;
    display: block;
    color: #FDDD50;
    text-shadow: 1px -1px 2px #764B1E, -1px 1px 2px #764B1E, 2px 2px 5px rgba(0, 0, 0, 0.2);
}


.tournament .iconBuck {
    display: block;
    position: absolute;
    top: -5px;
    inset-inline-start: -5px;
    width: 35px;
    height: 35px;
    background: center/cover no-repeat url(../../../public/assets/icons/pileOfMoney.png);
    z-index: 2;
}

.tournament .tournamentContainer .reward {

    font-size: 16pt;
    display: block;
    width: 140px;
    height: auto;
    background: #ffffff;
    position: relative;
    border-radius: 5px;
    margin-top: 3px;

    margin-left: auto;
    margin-right: auto;

    color: #76a715;
    text-shadow: -1px 1px 2px #325201;

    border-top: solid 2px #fff;
    border-bottom: solid 2px #D0C082;
    border-inline-end: none;
    border-inline-start: none;

}