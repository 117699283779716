.offline {
    width: 400px;
    min-height: 250px;
}


.offline .layer {
    width: 320px;
    height: 160px;
    margin-bottom: 5px;
    margin-top: 10px;
    position: relative;
    float: left;

}

.offline .layer:dir(rtl) {
    float: right;
}

.offline .titre {
    width: 76%;
}

.offline .sousTitre {
    font-size: 12px;
    text-align: center;
    margin-bottom: 5px;
    margin-top: 5px;
}


.offline .bouton {
    margin-top: 10px;
    margin-bottom: 10px;

    width: 200px;
    margin-left: auto;
    margin-right: auto;
    clear: both;
}

.offline .unlockedAchievement {
    display: block;
    width: 200px;
    height: 200px;
}


.offline .unlockedAchievement img {
    display: block;
    width: 100px;
    height: 100px;
    float: left;
    margin-left: 40px;
    margin-right: 40px;
    z-index: 3;

}

.offline .description {
    display: block;
    text-align: center;
    float: left;
    height: 33px;

    width: 160px;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
}

.offline .perso {
    display: block;
    width: 240px;
    height: 120px;
    position: absolute;
    top: -100px;
    right: 50px;
    background: center/cover no-repeat url(/public/assets/interfaces/persoMouche.png);
}


.offline .perso:dir(rtl) {
    transform: scaleX(-100%);
}