.deleteRequest {
    width: 385px;
    min-height: 260px;
}


.deleteRequest .layer {
    width: 100%;
    height: 180px;
    margin-bottom: 5px;
    margin-top: 5px;

}


.deleteRequest .titre {
    width: 76%;
}

.deleteRequest .sousTitre {
    font-size: 12px;
    text-align: center;
    margin-bottom: 15px;
    margin-top: 10px;
}

.deleteRequest .bouton {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 90%;
}

.deleteRequest .sousTitre {
    text-align: center;
    margin-bottom: 20px;
}

.deleteRequest .perso {
    display: block;
    width: 240px;
    height: 130px;
    position: absolute;
    top: -110px;
    right: 60px;
    background: center/cover no-repeat url(/public/assets/interfaces/persoMouche.png);
}



.deleteRequest i.icon-ok {
    inset-inline-end: 35px;
    width: 1em;
    inset-inline-start: unset;
    color: rgb(0, 200, 0);

}

.deleteRequest i.icon-cancel,
.deleteRequest i.icon-notavailable {
    inset-inline-end: 35px;
    width: 1em;
    inset-inline-start: unset;
    color: rgb(200, 0, 0);
}

.deleteRequest i {
    display: inline;
    padding: 10px;
    color: #694523;
    text-align: center;
    position: absolute;
    inset-inline-start: 24px;
}

.deleteRequest input {
    text-indent: 30px;
}