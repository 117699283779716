.stepper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 140px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
}

.stepper.small {
    height: 55px;
    width: 70%;
}

.stepper.compact {
    width: 110px;
    height: 110px;
    margin: 0;
}

.stepper.store {
    width: 100%;
    height: 200px;
}










.stepper.friends {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 75%;
    height: 65px;
    float: left;

    margin-top: -4px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
}

.stepper.friends:dir(rtl),
.stepper.friends .counter:dir(rtl),
.stepper .fleche.left:dir(rtl),
.stepper .counter:dir(rtl),
.counter span:dir(rtl) {
    float: right;
}

.stepper.friends .fleche {
    width: 40px;
    height: 40px;
    margin-top: 0px
}

/*
.stepper.friends .fleche::before {
    content:none;
    content: url(/public/assets/interfaces/motif-bouton-smaller.png);
}
*/
.stepper.friends .counter {
    width: 100%;
    height: 65px;
    float: left;
    margin-top: 0px;
    border: none;
    background: none;
    box-shadow: none;
    overflow-y: visible;


}






















.stepper.games {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    width: 100%;
    height: 50px;

    margin: 0;
    margin-left: auto;
    margin-right: auto;
}

.stepper.games:dir(rtl),
.stepper.games .counter:dir(rtl) {
    float: right;
}

.stepper.games .fleche {
    width: 40px;
    height: 40px;
    margin-top: 0px
}

/*
.stepper.games .fleche::before {
   content:none;
    content: url(/public/assets/interfaces/motif-bouton-smaller.png);
}
*/
.stepper.games .counter {
    width: 100%;
    height: 55px;
    float: left;
    margin-top: 0px;
    border: none;
    background: none;
    box-shadow: none;
    overflow-y: visible;


    background: linear-gradient(#FEF9E6, #FEEFC4);
    border-radius: 7px;

    border: solid 1px #E7D790;
    box-shadow: 0px 2px #A59655;

}





























.stepper.store .counter {
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    border-radius: 0;
    box-shadow: none;
}

.stepper.store .counter span {

    float: left;

}

.stepper.store .counter .container {
    height: 205px;
    display: block;
    column-count: 1;
    column-gap: 4px;
    padding-inline-start: 12px;
}









.stepper.compact .counter {
    width: 100px;
    height: 100px;
}

.stepper.compact .fleche {
    position: absolute;
    bottom: 7px;
    inset-inline-end: 5px;
    width: 30px;
    height: 30px;
    z-index: 2;
}

.stepper.compact .fleche.left {
    inset-inline-end: 30px;
}

/*
.stepper.compact .fleche::before {
    content:none;
    content: url(/public/assets/interfaces/motif-bouton-smaller.png);
}
*/


.stepper .fleche {
    width: 55px;
    height: 55px;
    float: right;
}

.stepper .fleche:dir(rtl) {
    float: left;

}

.stepper .fleche.left {
    float: left;

}

/*
.stepper .fleche::before {
    content: url(/public/assets/interfaces/motif-bouton-left.png);
}
*/
.stepper .counter {
    overflow: hidden;
    scroll-behavior: smooth;


    width: 120px;
    height: 120px;
    border: solid 4px #fff;
    border-radius: 10%;
    background: linear-gradient(rgba(256, 256, 256, 0.8), rgba(256, 256, 256, 0.2));
    ;
    margin-left: auto;
    margin-right: auto;
    float: left;
    box-shadow: -4px 4px 0 rgba(0, 0, 0, 0.1);

}

.stepper.small .counter {
    width: 55px;
    height: 55px;
}



/*
::-webkit-scrollbar {
    display: none;
}*/

.counter span {
    display: block;
    /* width: 120px;
    height: 120px; 
    float: left;*/
}

.stepper.small .counter span {
    height: 55px;
    width: 55px;
}

.counter .container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: fit-content;
    height: auto;

}






.stepper.versus {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    width: 100%;
    height: 200px;

    margin: 0;
    margin-left: auto;
    margin-right: auto;
}

.stepper.versus:dir(rtl),
.stepper.versus .counter:dir(rtl) {
    float: right;
}


.stepper.versus .counter {
    width: 180px;
    height: 180px;
}