.listing .layer {
    height: 225px;
    margin-bottom: 5px;
    margin-top: 5px;
}

.listing .titre {
    margin: 0px
}





.roomScroll {
    display: block;
    width: 100%;
    height: 212px;
    overflow: hidden;
    scroll-behavior: smooth;

}

.listing .fleche {
    width: 25px;
    float: right;
    margin-inline-end: 8px;
}

.listing .bouton.create {
    min-width: 200px;
    float: left;

}

.listing .bouton.refresh {
    min-width: 220px;
    float: left;
    margin-inline-start: 10px;

}

.listing .inputFilter {
    width: 150px;
    height: 30px;
    float: right;
    margin: 0;
    margin-right: -36px;
    margin-top: 3px;
}