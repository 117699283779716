.forgot {
    width: 370px;
    min-height: 250px;
}


.forgot .layer {
    width: 100%;
    height: 170px;
    margin-bottom: 5px;
    margin-top: 5px;

}

.forgot .titre {
    width: 76%;
}

.forgot .sousTitre {
    font-size: 12px;
    text-align: center;
    margin-bottom: 15px;
    margin-top: 10px;
}


.forgot .bouton {
    margin-top: 15px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    clear: both;
}


.forgot i.icon-ok {
    inset-inline-end: 35px;
    width: 1em;
    inset-inline-start: unset;
    color: rgb(0, 200, 0);

}

.forgot i.icon-cancel,
.forgot i.icon-notavailable {
    inset-inline-end: 35px;
    width: 1em;
    inset-inline-start: unset;
    color: rgb(200, 0, 0);
}

.forgot i {
    display: inline;
    padding: 10px;
    color: #694523;
    text-align: center;
    position: absolute;
    inset-inline-start: 24px;
}

.forgot input {
    text-indent: 30px;
}


.forgot .perso {
    display: block;
    width: 240px;
    height: 120px;
    position: absolute;
    top: -100px;
    right: 50px;
    background: center/cover no-repeat url(/public/assets/interfaces/persoMouche.png);
}