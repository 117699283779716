.panel.profil {
  width: 450px;
  height: 350px;
}

.panel.profil .layer {
  height: 270px;

}

.panel.profil .indicateurPremium {
  position: absolute;
  top: -20px;
  inset-inline-start: -15px;
  height: 50px;
  width: 50px;
  z-index: 3;
}


.panel.profil .profile {
  display: block;
  height: 110px;
  width: 110px;

  border-radius: 0 0 10px 10px;

  /* box-shadow: 0px 0px 0px 1px #ead365,
    -2px 5px 5px 5px rgba(0, 0, 0, 0.2);
    */

  padding: 0;
  margin-inline-end: 5px;
  position: relative;

  float: left;
}

.panel.profil .profilContainer {
  display: block;
  height: 100%;
  width: 100%;
  background: linear-gradient(#000000 50%, #343434) 100% 100% no-repeat;
  border-radius: 7px;
  overflow: hidden;
  position: relative;
}

.panel.profil .online {
  display: block;
  width: 7px;
  height: 7px;
  border-radius: 10px;
  background-color: #a49453;
  position: absolute;
  top: 4px;
  inset-inline-end: 4px;
  border: solid 1px #bfae81;
  border-bottom: solid 1px #e7e5de;
}

.panel.profil .online.on {
  background-color: #15ff00;
  box-shadow: 0 0 5px 3px #15ff00;
}

.panel.profil .online.off {
  background-color: #a49453;
}

.panel.profil .refrac {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  background: center/cover no-repeat url(/public/assets/interfaces/refractionEffect.png);
}

.panel.profil .image {
  display: block;
  height: 100%;
  width: 100%;
}



.panel.profil .section .titre:dir(rtl),
.panel.profil .section .vr:dir(rtl),
.panel.profil .bouton:dir(rtl) {
  float: left;
}

.panel.profil .bouton {
  width: 90px;
  height: 26px;
  float: right;
  inset-inline-end: 10px;
  padding: 0;
  min-width: unset;
}

.panel.profil .name {
  margin-inline-start: 10px;
  margin-inline-end: 10px;

  width: fit-content;
  overflow: hidden;
  display: block;
  height: 24px;
  line-height: 0.8;
}

.panel.profil .flag {
  display: block;
  height: 15px;
  width: auto;
  float: left;
  margin-top: 2px;
  border-radius: 3px;
}


.panel.profil .calque {
  width: 98%;
  height: 110px;
  margin: 0;
  margin-bottom: 10px;
}

.panel.profil hr {
  clear: right;
  margin-bottom: 5px;
}

.panel.profil hr:dir(rtl),
.panel.profil .indicatorSection:dir(rtl) {
  clear: left;

}

.panel.profil .sousTitre {
  margin-top: 5px;
  float: left;
}


.panel.profil .calque .bouton-icone {
  margin: 0;
}

.panel.profil .indicatorSection {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  height: 55px;
  width: 275px;
  flex-wrap: wrap;
  align-content: space-between;
  margin-top: 40px;
  clear: right;
}

.profil .indicateur {
  margin: 0;
  float: none;
}


.panel.profil .section {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 50px;
  width: 415px;
  flex-wrap: wrap;
  align-content: space-between;
}


.panel.profil .section .vr {
  float: right;
}

.panel.profil .section>div {
  display: block;
  width: 130px;

  margin: 3px;
  margin-top: 7px;
  height: 45px;
  float: left;
}


.panel.profil .flag:dir(rtl),
.panel.profil .name:dir(rtl),
.panel.profil .profile:dir(rtl),
.panel.profil .sousTitre:dir(rtl),
.panel.profil .section>div:dir(rtl),
.panel.profil .section .sousTitre:dir(rtl) {
  float: right;
}




.panel.profil .section .titre {
  font-size: 25px;
  float: right;
  clear: both;
  text-align: end;
  margin: 0;
  display: block;
  width: auto;
}

.panel.profil .section .titre.levelTitle {
  font-size: 16px;
  line-height: 2em;
}

.panel.profil .section .sousTitre {
  font-size: 12px;
  float: left;
  text-align: start;
  margin: 0;
  display: block;
  width: auto;
  overflow: hidden;
  height: 14px;
}