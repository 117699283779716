.circle-wrap {
    margin: 3px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background:linear-gradient(#F3E8B5 20%, #DDC37F 80%) ;
    border: 1px solid #A59655;
    border-top: 1px solid #FCE8D0;
    box-shadow: -4px 4px 0px  rgba(0, 0, 0, 0.1) ,
    inset 0px -2px 0 #A59655;

    float: right;
  }
  .circle-wrap:dir(rtl) {
    float: left;

  }

  .circle-wrap .circle .mask,
  .circle-wrap .circle .fill {
    width: 22px;
    height: 22px;
    position: absolute;
    border-radius: 50%;
  }
  
  .circle-wrap .circle .mask {
    clip: rect(0px, 22px, 22px, 11px);
  }
  
  
  /* color animation */
  

  /* 3rd progress bar */
  .mask .fill {
    clip: rect(0px, 11px, 22px, 0px);
    background-color: #227ded;
  }
  
  .mask.full,
  .circle .fill {
    transform: rotate(180deg);
  }

  .circle-wrap .inside-circle {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    margin:0;
    position: relative;
    background:linear-gradient(#F3E8B5 20%, #DDC37F 80%) ;
    box-shadow: inset 0px -2px 0 #A59655;
  }

  .circle-wrap .outside-circle {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    margin:0;
    position: relative;
    background:radial-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.2) 80%) ;
    border: 1px solid #A59655;
    border-top: 1px solid #FCE8D0;
    box-shadow: inset 0px -2px 0 #A59655;
    display: block;
    position: absolute;
    z-index: 1;
  }
  
  .circle-wrap .circle {
    width: 22px;
    height: 22px;
    margin:0;
    position: absolute;
    z-index: 1;
  }

/*
  @keyframes fill{
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }*/
  


  /*
  
  .circle-wrap {
    margin: 22px auto;
    width: 22px;
    height: 22px;
    background: #fefcff;
    border-radius: 50%;
    border: 1px solid #cdcbd0;
  }
  
  .circle-wrap .circle .mask,
  .circle-wrap .circle .fill {
    width: 22px;
    height: 22px;
    position: absolute;
    border-radius: 50%;
  }
  
  .circle-wrap .circle .mask {
    clip: rect(0px, 22px, 22px, 75px);
  }
  
  .circle-wrap .inside-circle {
    width: 122px;
    height: 122px;
    border-radius: 50%;
    background: #d2eaf1;
    line-height: 120px;
    text-align: center;
    margin-top: 14px;
    margin-inline-start: 14px;
    color: #1e51dc;
    position: absolute;
    z-index: 100;
    font-weight: 700;
    font-size: 2em;
  }
  
  
  .mask .fill {
    clip: rect(0px, 22px, 22px, 0px);
    background-color: #227ded;
  }
  
  .mask.full,
  .circle .fill {
    animation: fill ease-in-out 3s;
    transform: rotate(135deg);
  }
  
  @keyframes fill{
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(135deg);
    }
  }
  
  */