.toogleStyle, .bouton-style{
    display: block;
    width: 30px;
    height: 30px;
    padding: 0;
    margin: 0;
    position: relative;
}


.bouton-style img{
    width: 100%;
    height: 100%;
    filter: drop-shadow(2px 2px 1px rgb(0,0,0,0.2)); 
}