.confirmReset {
    min-height: 250px;
}

.confirmReset .layer {
    height: 170px;
}

.confirmReset .bouton {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.confirmReset .sousTitre {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
}

.confirmReset .perso {
    display: block;
    width: 240px;
    height: 120px;
    position: absolute;
    top: -100px;
    right: 50px;
    background: center/cover no-repeat url(/public/assets/interfaces/persoMouche.png);
}