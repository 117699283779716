.settings {
    display: block;
}

.settings .titre {
    width: 70%;
    margin-bottom: 5px;
}


.settings .layer {
    clear: right;
    height: 245px;
}

/*
.settings .layer:dir(rtl) {
    float: left;
    clear: left;

}

.settings .layer.first {
    float: left;
}


.settings .layer.first:dir(rtl),
.settings .sousTitre:dir(rtl),
.settings .message:dir(rtl),
.settings .calque.langue:dir(rtl),
.settings .calque.style:dir(rtl),
.settings .bouton:dir(rtl) {
    float: right;
}
*/





.settings .section.theme {
    margin-inline-end: 10px;
}

.settings .section {
    display: block;
    width: 284px;
    height: 115px;
    overflow: hidden;
    position: relative;
    float: left;
}

.settings .section:dir(rtl) {
    float: right;

}


.settings .stepper {
    float: right;
}

.settings .stepper:dir(rtl) {
    float: left;
}


.settings .sousTitre {
    display: block;
    font-size: 10pt;
    text-align: start;

}

.settings .message {
    clear: left;
    display: block;
    text-align: start;
    float: left;
    width: 160px;
    height: 30px;
}

.settings .message:dir(rtl) {
    clear: right;
    float: right;
}

/*
.settings .stepper{
    
    position: absolute;
    top:0;
   inset-inline-end: 0;
}
*/
.settings .calque {
    height: 110px;
    padding: 10px;
    margin: 0;
    margin-bottom: 10px;
}



.settings .calque.style {
    width: 115px;
    height: 80px;
    float: left;
    margin-inline-end: 10px;
}

.settings .calque.style:dir(rtl) {
    float: right;
}

.settings .calque.vitesse {
    width: 262px;
    height: 80px;
    float: left;
}

.settings .calque.vitesse:dir(rtl) {
    float: right;
}

.settings .calque.vitesse .slider {
    margin-top: 15px;
}

.settings .section .bouton {
    float: left;
    width: 148px;
    min-width: auto;
    margin-top: 28px;
    clear: left;
}

.settings .section .bouton:dir(rtl) {
    float: right;
    clear: right;
}

.settings .item {
    width: 100px;
    height: 100px;
}

.settings .item img {
    width: 100%;
    height: 100%;
}

.settings .toogleContainer {
    display: block;
    width: 30px;
    height: 30px;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    float: none;
    position: relative;
}




















.settings .onglet {
    list-style-type: none;
    float: left;
    margin: 0;
    padding: 0;
    margin-bottom: -4px;
    position: relative;

}

.settings .onglet:dir(rtl) {
    float: right;

}

.settings .onglet li {
    display: block;
    float: right;
    cursor: pointer;
    font-size: 13px;
    font-weight: bold;
    color: #644629;
    text-shadow: 0 2px 1px #fff;

    /* 
    border-radius: 3%/50%;
    border: solid 2px #fff;
    border-left: solid 2px #cec08a;
    border-bottom: none; 
    background-color: #f7ebc1;
    

    width: 125px;*/
    height: 25px;

    line-height: 2em;
    padding: 5px 15px 5px 15px;
    margin-inline-end: -15px;

    position: relative;
    z-index: 0;
    background: center/cover no-repeat url(../../../public/assets/interfaces/onglet-Off.png);
    background-size: 100% 100%;

}

.settings .onglet li:dir(rtl) {
    float: left;
}

.settings .onglet li:hover,
.settings .onglet li.active {
    background: center/cover no-repeat url(../../../public/assets/interfaces/onglet-On.png);
    background-size: 100% 100%;

    z-index: 1;
}




.settings .calque.sound {
    width: 262px;
    float: left;
    height: 100px;
    margin-inline-start: 10px;
}

.settings .calque.sound:dir(rtl) {
    float: right;

}

.settings .calque.sound .fleche {
    width: 28px;
    height: 28px;
    float: right;
    margin: 3px;
}

.settings .calque.sound .fleche::before {
    top: 1px;
    inset-inline-start: 2px;
}


.settings .calque.volume {
    width: 262px;
    float: left;
    height: 100px
}

.settings .calque.volume:dir(rtl) {
    float: right;
}

.settings .soundOptions {
    display: block;
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto;
    width: fit-content;
    height: auto;
}


.settings .soundOptions .fleche:dir(rtl) {
    float: left;
}



.settings .calque.form {
    width: 262px;
    float: left;
    margin-inline-end: 9px;
    height: 200px;
    /*overflow: hidden;*/
}

.settings .calque.form:dir(rtl) {
    float: right;
}

.settings .form input {
    text-indent: 30px;
}

.settings .calque.form .formContainer {
    display: block;
    position: relative;
}

.settings .calque.form i.icon-ok {
    inset-inline-end: 25px;
    width: 1em;
    inset-inline-start: unset;
    color: rgb(0, 200, 0);

}

.settings .calque.form i.icon-cancel,
.settings .calque.form i.icon-notavailable {
    inset-inline-end: 25px;
    width: 1em;
    inset-inline-start: unset;
    color: rgb(200, 0, 0);

}

.settings .calque.form i {
    display: inline;
    padding: 10px;
    color: #694523;
    text-align: center;
    position: absolute;
    inset-inline-start: 18px;
}

.settings .form .bouton {
    margin-right: auto;
    margin-left: auto;
    width: 212px;

}

.formContainer span {
    display: block;
    height: 30px;
    width: fit-content;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    line-height: 2.5em;
    font-size: 14px;
    color: #694523;
}



.settings .calque.country {
    width: 262px;
    float: left;
    height: 84px;

}

.settings .calque.voice img {
    float: left;
    width: 30px;
    height: auto;
    border-radius: 5px;
    margin-inline-end: 15px;

}

.settings .calque.country img {
    float: left;
    width: 30px;
    height: auto;
    border-radius: 5px;
    margin: 5px;
    margin-inline-end: 15px;

}

.settings .calque.voice img:dir(rtl),
.settings .calque.country img:dir(rtl) {
    float: right;
}


.settings .calque.voice {
    width: 265px;
    height: 75px;
    float: left;
}

.settings .calque.langue {
    width: 115px;
    float: left;
    height: 84px;
}

.settings .calque.langue:dir(rtl) {
    float: right;
}

.settings .calque.profil {
    width: 115px;
    float: left;
    height: 84px;
    margin-inline-start: 10px;
}

.settings .calque.profil:dir(rtl) {
    float: right;
}

.settings select {

    width: 150px;

}

.settings .voiceSelector,
.settings .country .flagSelector {
    display: block;
    width: 200px;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.settings .voiceSelector img {
    width: 50px;
    height: 50px;
}






.settings .calque.profil .profilContainer {
    display: block;
    height: 60px;
    width: 60px;
    background: linear-gradient(#000000 50%, #343434) 100% 100% no-repeat;
    border-radius: 7px;
    overflow: hidden;
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

.settings .calque.profil .refrac {
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    background: center/cover no-repeat url("/public/assets/interfaces/refractionEffect.png");
}

.settings .calque.profil img {
    display: block;
    height: 100%;
    width: 100%;
}

.settings .calque.profil .profilContainer .bouton-icone.icon-language {
    inset-inline-start: 0px;

}

.settings .calque.profil .profilContainer .bouton-icone {
    height: 16px;
    width: 16px;
    font-size: 9pt;
    line-height: 1.2em;
    position: absolute;
    bottom: 0;
    inset-inline-end: 0;
    margin: 0;
}

.settings .calque.profil .upload {
    overflow: hidden;
}

.settings .calque.profil .upload input {
    opacity: 0;
    width: 0;
    height: 0;
    display: none;
}