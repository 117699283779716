.itemPendingInvitation {
    display: block;

    width: 100%;
    height: 43px;
    padding: 0;
    margin: 0;
}


.itemPendingInvitationContainer {
    display: block;

    width: 100%;
    height: 34px;
    background: linear-gradient(#FEF9E6, #FEEFC4);
    border-radius: 7px;

    border: solid 1px #E7D790;
    box-shadow: 0px 2px #A59655;
    padding: 0;

    float: none;

}


.itemPendingInvitationContainer .imageContainer:dir(rtl),
.itemPendingInvitationContainer .name:dir(rtl) {

    float: right;


}

.itemPendingInvitationContainer .flag:dir(rtl),
.name:dir(rtl) {
    float: right;
}


.itemPendingInvitationContainer .flag {
    display: inline-block;
    height: 12px;
    width: auto;
    position: absolute;
    bottom: -1px;
    right: -2px;
    border-radius: 2px;
}


.itemPendingInvitationContainer .imageContainer {
    width: 32px;
    height: 32px;
    margin-top: 1px;
    margin-inline-start: 5px;
    float: left;
    position: relative;
    overflow: hidden;
    border-radius: 6px;

}

.itemPendingInvitationContainer .image {
    margin: 0;
    width: 32px;
    height: 32px;
}


.itemPendingInvitationContainer .name {
    height: 34px;
    line-height: 2.7em;

    float: left;
    margin-inline-start: 5px;

    font-size: 14px;
    font-weight: 700;

    color: #694523;
    display: block;
    width: 170px;
}


.itemPendingInvitation .desactivated {
    filter: saturate(0);
    pointer-events: none;
}

.itemPendingInvitation .itemPendingInvitationContainer .bouton.simple.small {
    position: relative;
    float: left;
    width: fit-content;
    width: 100px;
    min-width: 100px;
    margin: 4px;
}



.itemPendingInvitationContainer .online {
    display: block;
    width: 7px;
    height: 7px;
    border-radius: 10px;
    background-color: #a49453;
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    border: solid 1px #bfae81;
    border-bottom: solid 1px #e7e5de;
}

.itemPendingInvitationContainer .online.on {
    background-color: #15ff00;
    box-shadow: 0 0 5px 3px #15ff00;
}

.itemPendingInvitationContainer .online.off {
    background-color: #a49453;
}