.languageToolTip {

    bottom: 27px;
    display: block;
    position: absolute;
    transform: translateX(-42%);
    width: 140px;
    height: 90px;
    pointer-events: auto;
    margin: 0;

    border-radius: 7px;
    background: linear-gradient(#F3E8B5 20%, #DDC37F 80%);
    border: 1px solid #A59655;
    border-top: 1px solid #FCE8D0;
    box-shadow: -4px 4px 0px rgba(0, 0, 0, 0.1),
        inset 0px -2px 0 #A59655;

    padding: 10px;
    z-index: 10;

}

.languageToolTip:dir(rtl) {
    transform: translateX(+44%);
}

.languageToolTip span.titre {
    font-size: 14pt;
    position: absolute;
    top: -15px;
    width: 90%;
    text-align: center;
    z-index: 2;
}


.languageToolTip .triangle {
    display: block;
    height: 0;
    width: 0;

    margin-bottom: -5px;


    border-inline-end: 5px solid transparent;
    border-inline-start: 5px solid transparent;
    border-top: 10px solid #DDC37F;
    transform: translateX(-5px);

    margin-left: auto;
    margin-right: auto;
    position: absolute;
    bottom: 0;
    inset-inline-start: 50%;
}

.entranceContainer .languageToolTip span.titre {
    position: absolute;
    top: auto;
    bottom: -15px;

}

.entranceContainer .languageToolTip {
    bottom: -114px;
}


.entranceContainer .languageToolTip .triangle {
    border-top: 10px solid #F3E8B5;


    position: relative;
    bottom: auto;
    top: -17px;
    inset-inline-start: 0%;
    transform: rotate(-62deg) translateX(0px);
}

.entranceContainer .languageToolTip .triangle:dir(rtl) {
    transform: rotate(-62deg) translateX(0px);

}


.entranceContainer .languageToolTip .triangle::before {
    margin-bottom: auto;
    /* margin-top: -18px; */
    transform: rotate(-90deg) translateX(-5px);
    position: absolute;
}


.entranceContainer .languageToolTip .languageContainer {
    margin-top: 10px;
}


.entranceContainer .languageToolTip .triangleMosaique {
    bottom: auto;
    top: 0;
    transform: rotate(-90deg) translateY(-50%);
}

.languageToolTip .triangle::before {
    display: block;
    position: absolute;

    margin-bottom: -8px;
    transform: rotate(90deg) translateX(-5px);


    border-inline-end: 10px solid transparent;
    border-inline-start: 10px solid transparent;
    border-top: 20px solid #A59655;
}

.languageToolTip .triangle:dir(rtl) {
    transform: translateX(5px);

}

.languageToolTip .triangleMosaique {
    display: block;
    height: 30px;
    width: 30px;
    position: absolute;
    bottom: 0;
    inset-inline-start: 50%;
    transform: rotate(90deg) translateY(50%);
    margin-left: auto;
    margin-right: auto;
    background: url(/public/assets/interfaces/motif-bouton-left-brun.png) left no-repeat;
}

.languageToolTip .triangleMosaique:dir(rtl) {
    transform: rotate(90deg) translateY(-50%);

}


.languageToolTip .bouton-icone {
    margin: 3px;
}

.languageToolTip .fleche {
    width: 28px;
    height: 28px;
    float: right;
    margin: 3px;
}

.languageToolTip .fleche:dir(rtl) {
    float: left;

}

.languageToolTip .fleche::before {
    top: 1px;
    inset-inline-start: 2px;
}

.languageToolTip .languageContainer {
    position: relative;
    display: block;
    width: 100%;
    height: 80%;

    margin-left: auto;
    margin-right: auto;

    border: solid 2px #fff;
    border-radius: 5px;
    background: linear-gradient(#e7dbc2 30%, #fff);
    box-shadow: 0 0 0 3px #e5d796;

}

.languageToolTip .toogleLanguage {
    float: right;
    margin: 3px;

}

.languageToolTip .toogleLanguage:dir(rtl) {
    float: left;
}