.slider {
    display: block;
    width: 100%;
    height: 30px;
    margin-top: 8px;
}


.slider .fleche {
    width: 30px;
    height: 30px;
    float: left;
}

.slider .fleche.left {
    float: left;

}

.slider .fleche:dir(rtl),
.slider .fleche.left:dir(rtl),
.slider .container:dir(rtl) {
    float: right;
}

/*
.slider .fleche::before {
    content:none;
    content: url(/public/assets/interfaces/motif-bouton-smaller.png);
}*/

.slider .sousTitre {
    clear: both;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 100%;
    text-align: center;
    height: fit-content;
    line-height: 1.6em;
    z-index: 2;
}


.slider .slide {
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(#ffe69e, #fbd359);
    inset-inline-start: 0;
}

.slider .container {
    position: relative;
    display: block;
    width: 74%;
    height: 20px;
    margin-top: 3px;
    margin-inline-start: 1px;


    border: solid 2px #fff;
    border-radius: 5px;
    background: linear-gradient(#e7dbc2 30%, #fff);
    ;

    float: left;
    box-shadow: 0 0 0 3px #e5d796;
    overflow: hidden;

}