.itemRank {
    display: block;

    width: 100%;
    height: 43px;
    padding: 0;
    margin: 0;
}


.itemRankContainer {
    display: block;

    width: 100%;
    height: 34px;
    background: linear-gradient(#FEF9E6, #FEEFC4);
    border-radius: 7px;

    border: solid 1px #E7D790;
    box-shadow: 0px 2px #A59655;
    padding: 0;

    float: none;

}

.itemRankContainer .index {
    display: block;
    margin: 0;
    float: left;
    width: 50px;
    height: 34px;
    border-radius: 7px 0 0 7px;
    color: white;
    text-shadow: 0 0 2px #3c2b15;
    font-size: 30px;
    line-height: 1em;
    font-weight: bolder;


    background: linear-gradient(#F3E8B5 20%, #DDC37F 80%);
    border: 1px solid #A59655;
    border-top: 1px solid #FCE8D0;
    box-shadow: inset 0px -2px 0 #A59655;
}

.itemRankContainer .index:dir(rtl),
.itemRankContainer .imageContainer:dir(rtl),
.itemRankContainer .name:dir(rtl),
.itemRankContainer .Level:dir(rtl),
.itemRankContainer .Level .ico:dir(rtl),
.itemRankContainer .Level .ico:dir(rtl),
.itemRankContainer .Achievement:dir(rtl),
.itemRankContainer .Achievement .ico:dir(rtl),
.itemRankContainer .Coin:dir(rtl),
.itemRankContainer .Win:dir(rtl),
.itemRankContainer .indicateurPremium:dir(rtl),
.itemRankContainer .Win .ico:dir(rtl),
.itemRankContainer .Coin .ico:dir(rtl),
.itemRankContainer .premium .ico:dir(rtl) {


    float: right;


}

.itemRankContainer .flag:dir(rtl),
.huitemRankContainerd .name:dir(rtl) {
    float: right;
}


.itemRankContainer .flag {
    display: inline-block;
    height: 12px;
    width: auto;
    position: absolute;
    bottom: -1px;
    right: -2px;
    border-radius: 2px;

}


.itemRankContainer .imageContainer {
    width: 32px;
    height: 32px;
    margin-top: 1px;
    margin-inline-start: 5px;
    float: left;
    position: relative;
    overflow: hidden;
    border-radius: 6px;

}

.itemRankContainer .image {
    margin: 0;
    width: 32px;
    height: 32px;
}


.itemRankContainer .name {
    height: 34px;
    line-height: 2.7em;

    float: left;
    margin-inline-start: 5px;

    font-size: 14px;
    font-weight: 700;

    color: #694523;
    display: block;
    width: 100px;
}


.itemRankContainer .Level {
    display: block;
    height: 100%;
    width: 65px;
    float: left;
    line-height: 2.7em;

    margin-inline-start: 1px;
    font-size: 14px;
    font-weight: 700;
    color: #694523;
}

.itemRankContainer .Level .ico {
    display: block;
    height: 20px;
  width: 26px;
  margin-top: 9px;
    float: left;
    background: center/cover no-repeat url(/public/assets/icons/Level.png);
}


.itemRankContainer .Win {
    display: block;
    height: 100%;
    width: 65px;
    float: left;
    line-height: 2.7em;

    margin-inline-start: 1px;
    font-size: 14px;
    font-weight: 700;
    color: #694523;
}

.itemRankContainer .Win .ico {
    display: block;
    height: 20px;
  width: 24px;
  margin-top: 8px;
    float: left;
    background: center/cover no-repeat url(/public/assets/icons/Win.png);
}


.itemRankContainer .Achievement {
    display: block;
    height: 100%;
    width: 60px;
    float: left;
    line-height: 2.7em;

    margin-inline-start: 1px;
    font-size: 14px;
    font-weight: 700;
    color: #694523;
}

.itemRankContainer .Achievement .ico {
    display: block;
    height: 26px;
    width: 24px;
    margin-top: 4px;
    float: left;
    background: center/cover no-repeat url(/public/assets/icons/Achievement.png);
}


.itemRankContainer .Coin {
    display: block;
    height: 100%;
    width: 90px;
    float: left;
    line-height: 2.7em;

    margin-inline-start: 1px;
    font-size: 14px;
    font-weight: 700;
    color: #694523;
}

.itemRankContainer .Coin .ico {
    display: block;
    height: 41px;
    width: 27px;
    margin-top: 5px;
    float: left;
    background: center/cover no-repeat url(/public/assets/icons/Coin.png);
}


.itemRankContainer .indicateurPremium {
    display: block;
    height: 100%;
    width: 30px;
    float: left;
    line-height: 2.7em;

    margin-inline-start: 5px;
    font-size: 14px;
    font-weight: 700;
    color: #694523;
}

.itemRankContainer .premium .ico {
    display: block;
    height: 21px;
    width: 27px;
    margin-top: 6px;
    float: left;
    background: center/cover no-repeat url(/public/assets/icons/Star.png);
}

.itemRankContainer .bouton-icone {
    margin: 3px;
    margin-inline-end: 6px;
}


.itemRankContainer .online {
    display: block;
    width: 7px;
    height: 7px;
    border-radius: 10px;
    background-color: #a49453;
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    border: solid 1px #bfae81;
    border-bottom: solid 1px #e7e5de;
}

.itemRankContainer .online.on {
    background-color: #15ff00;
    box-shadow: 0 0 5px 3px #15ff00;
}

.itemRankContainer .online.off {
    background-color: #a49453;
}