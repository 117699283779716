.hudContainer {
  display: block;
  height: 60px;
  width: 200px;
  position: absolute;
  pointer-events: auto;
  margin: 10px;
}


.hud {
  display: block;
  height: 60px;
  width: 200px;

}


.hud .indicateurPremium {
  height: 30px;
  width: 30px;
  position: absolute;
  top: -20px;
  left: -9px;
  z-index: 3;
}

.cardige {
  height: 22px;
  width: 45px;

  cursor: default;
  padding: 0;
  position: relative;


  text-align: center;
  text-indent: 0;
  display: block;
  line-height: 1.5em;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.4);
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;

  margin: 5px;

  border-radius: 5px;

  float: right;

  color: #8C7D3F;
  text-shadow: -2px 2px 0px #FFF4D2;
  background: linear-gradient(#F3E8B5 20%, #DDC37F 80%);
  border: 1px solid #A59655;
  border-top: 1px solid #FCE8D0;
  box-shadow: -4px 4px 0px rgba(0, 0, 0, 0.1),
    inset 0px -2px 0 #A59655;
}

.cardige:dir(rtl) {
  float: left;

}

.hud .image {
  display: block;
  height: 45px;
  width: 45px;
  background: linear-gradient(#000000 50%, #343434) 100% 100% no-repeat;
  box-shadow: inset 2px 2px 5px 1px rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  margin: 4px;
}

.hud .imageContainer {
  display: block;
  width: 54px;
  height: 54px;
  background: linear-gradient(#FEF9E6, #FEEFC4);
  border-radius: 7px 7px 7px 7px;
  border-start-end-radius: 0px;
  border: solid 1px #E7D790;
  box-shadow: 0px 2px #A59655;
  padding: 0;
  margin: 0;
  position: relative;
  float: left;
}

.hud .imageContainer:dir(rtl) {
  float: right;
}

.hud .buttons {
  display: block;
  float: left;
  width: 25px;
  margin-inline-start: -25px;
}

.hud .buttons .bouton-icone {
  height: 15px;
  width: 15px;
  font-size: 9pt;
  line-height: 1.2em;
  margin: 1px;
  float: left;
  text-indent: -1px;

}

.hud .imageContainer .bouton-icone {
  height: 16px;
  width: 16px;
  font-size: 9pt;
  line-height: 1.2em;
  position: absolute;
  bottom: 0;
  inset-inline-end: 0;
}

.hud .imageContainer .icon-cam-on {
  pointer-events: none;
  inset-inline-end: unset;
  inset-inline-end: 0;


}

.hud .imageContainer .bouton-icone.icon-disc {
  height: 20px;
  width: 20px;
  font-size: 12pt;
  position: absolute;
  top: 12px;
  inset-inline-start: 12px;
  z-index: 6;
}


.hud .imageContainer .bouton-icone.icon-pause {
  height: 20px;
  width: 20px;
  font-size: 12pt;
  position: absolute;
  top: 12px;
  inset-inline-start: 12px;
  z-index: 3;
}


.hud .imageContainer .focus {
  display: block;
  height: 42px;
  width: 42px;
  position: absolute;
  z-index: 2;
  border: 4px solid #227ded;
  border-radius: 5px;
  top: 2px;
  right: 2px;
  pointer-events: none;

}

.hud .name {
  display: block;
  height: 24px;
  width: 140px;
  text-indent: 6px;
  font-weight: 700;
  background: linear-gradient(#FEF9E6, #FEEFC4);
  border-radius: 0;
  border-start-end-radius: 7px;
  border-end-end-radius: 7px;
  border: solid 1px #E7D790;
  border-inline-start: none;
  box-shadow: 0px 2px #A59655;
  padding: 0;
  margin: 0;
  margin-inline-start: -1px;
  float: left;
  text-align: start;
  color: #694523;
  font-size: 14px;
  line-height: 1.7em;
  text-shadow: -2px 2px 0px #FFF4D2;
}

.hud .name .text {
  display: block;
  width: 100px;
  height: 25px;
  overflow: hidden;
  float: left;
}

.hud .name .text:dir(rtl),
.hud .flag:dir(rtl),
.hud .name:dir(rtl) {
  float: right;
}


.hud .flag {
  display: block;
  height: 15px;
  width: auto;
  margin: 4px;
  float: left;
}

.hud .round {
  display: block;
  height: 30px;
  width: 25px;
  font-size: 12px;
  position: relative;
  color: #E7D790;
  text-shadow: 0 0 2px #694523;
  z-index: 2;
  text-align: center;
  text-indent: 0px;
}

.hud .scoreContainer {
  display: block;
  height: 30px;
  width: 25px;
  position: absolute;
  inset-inline-end: -10px;
  top: 0;
}

.hud .icon {
  display: block;
  height: 25px;
  width: 25px;
  position: absolute;
  z-index: 2;
  top: 0;
  inset-inline-start: 0;
  stroke: #E7D790;
  stroke-width: 5%;
}

.hudContainer .pauseProgressBar {
  display: block;
  width: 192px;
  margin: 4px;
  margin-top: -8px;
  height: 4px;
  background: linear-gradient(#FFE015 30%, #D7C400);
}


.hudContainer .imageContainer .showbot {
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
  bottom: 5px;
  inset-inline-start: 5px;
  background: center/cover no-repeat url(/public/assets/icons/bot.png);
}


@media only screen and (max-width: 800px),
(max-height: 475px) {
  .hudContainerInterne {
    transform: scale(0.75);
  }
}


/*
.hud .refrac{
  
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top:0;
  background: center/cover no-repeat url("../../public/assets/interfaces/refractionEffect.png");
  z-index: 1;
 
}
*/