.sneakpeek {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
}

.sneakpeek .logo {
    display: block;
    width: 180px;
    height: 180px;
    background: center / contain no-repeat url(/public/assets/interfaces/logo.png);
    position: absolute;
    top: 0;
    left: 0;
}

.sneakpeek .game {
    display: block;
    width: 180px;
    margin-top: 10px;
    height: auto;
    position: absolute;
    top: 0;
    right: 0;
}



@media only screen and (max-width: 800px),
(max-height: 475px) {

    .sneakpeek .game,
    .sneakpeek .logo {
        transform: scale(0.5);
    }
}