.store .layer {
    height: 240px;
}

.store .titre {
    margin: 3px
}

.store .onglet {
    list-style-type: none;
    float: left;
    margin: 0;
    padding: 0;
    margin-bottom: -4px;
    position: relative;

}

.store .onglet:dir(rtl) {
    float: right;

}

.store .onglet li {
    display: block;
    float: right;
    cursor: pointer;
    font-size: 13px;
    font-weight: bold;
    color: #644629;
    text-shadow: 0 2px 1px #fff;

    /* 
    border-radius: 3%/50%;
    border: solid 2px #fff;
    border-left: solid 2px #cec08a;
    border-bottom: none; 
    background-color: #f7ebc1;
    

    width: 108px;*/
    height: 25px;

    line-height: 2em;
    padding: 5px 15px 5px 15px;
    margin-inline-end: -15px;

    position: relative;
    z-index: 0;
    background: center/cover no-repeat url(../../../public/assets/interfaces/onglet-Off.png);
    background-size: 100% 100%;
    white-space: nowrap;
    overflow: hidden;
}

.store .onglet li:dir(rtl) {
    float: left;
}

.store .onglet li:hover,
.store .onglet li.active {
    background: center/cover no-repeat url(../../../public/assets/interfaces/onglet-On.png);
    background-size: 100% 100%;
    z-index: 3;
}

.store .layerNone {
    display: none;
}