.entete {
  display: block;
  pointer-events: auto;



  position: absolute;
  top: 0;

  width: 680px;
  height: 55px;

  background: linear-gradient(var(--base-color-header), var(--base-color-header-trans)),
    linear-gradient(#E5D07B 25%, transparent) center/90% 100% no-repeat,
    var(--pattern) center/100px repeat,
    linear-gradient(var(--base-color-trans), var(--base-color));


  border-radius: 70% / 25%;

  margin-left: auto;
  margin-right: auto;

  border: #D3BF72 solid 7px;
  border-top: none;

  border-inline-start: none;
  border-inline-end: none;
  box-shadow: 0px 0px 0px 2px #f7e9a8,
    0px 0px 10px 10px rgba(0, 0, 0, 0.3);

  z-index: 2;

}

.entete:after {
  content: no-close-quote;
  display: block;

  position: absolute;
  top: -15px;
  bottom: 0;
  inset-inline-end: -15px;
  inset-inline-start: -15px;
  background: inherit;
  border: inherit;
  border-radius: 0 0 15px 15px;

  border-top: none;

  border-bottom: none;


  border: #D3BF72 solid 6px;
  border-bottom: 0;

  z-index: 0;
}


.enteteContainer {
  display: block;
  z-index: 1;
  position: relative;
  padding: 0;
  float: right;
  padding: 5px;
}

.enteteContainer:dir(rtl) {
  float: left;

}


.entete .profil {
  display: block;
  height: 65px;
  width: 65px;
  background: linear-gradient(#eadd96 25%, #c8b566) 100% 100% no-repeat;
  border: #c4b173 5px solid;
  border-top: 0;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 0px 0px 1px #ead365,
    -2px 5px 5px 5px rgba(0, 0, 0, 0.2);
  padding: 3px;

  padding-top: 6px;

  margin-inline-start: 2px;
  position: relative;
  z-index: 1;
  float: left;
}

.entete .profil:dir(rtl) {
  float: right;

}


.entete .profilContainer {
  display: block;
  height: 100%;
  width: 100%;
  background: linear-gradient(#000000 50%, #343434) 100% 100% no-repeat;
  border-radius: 7px;
  overflow: hidden;
  position: relative;
}

.entete .refrac {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  background: center/cover no-repeat url("/public/assets/interfaces/refractionEffect.png");
}

.entete .image {
  display: block;
  height: 100%;
  width: 100%;
}


.entete .profilContainer .bouton-icone {
  height: 16px;
  width: 16px;
  font-size: 9pt;
  line-height: 1.2em;
  position: absolute;
  bottom: 0;
  inset-inline-end: 0;
  margin: 0;
}

.entete .profil .indicateurPremium {
  position: absolute;
  top: -3px;
  inset-inline-start: -10px;
  height: 40px;
  width: 40px;
  z-index: 3;
}



.entete .profilContainer .online {
  display: block;
  width: 7px;
  height: 7px;
  border-radius: 10px;
  background-color: #a49453;
  position: absolute;
  top: 0;
  inset-inline-end: 0;
  border: solid 1px #bfae81;
  border-bottom: solid 1px #e7e5de;
}

.entete .profilContainer .online.on {
  background-color: #15ff00;
  box-shadow: 0 0 5px 3px #15ff00;
}

.entete .profilContainer .online.off {
  background-color: #a49453;
}