.indicateur {
    position: relative;
    display: block;
    width: 90px;
    height: 16px;
    margin: 5px;
    margin-top: 8px;

    margin-inline-start: 20px;

    pointer-events: none;


    border: solid 2px #fff;
    border-radius: 5px;
    background: linear-gradient(#e7dbc2 30%, #fff);
    ;

    float: right;
    box-shadow: 0 0 0 3px #e5d796, -2px 2px 0 3px rgba(0, 0, 0, 0.2);
    /*overflow: hidden;*/
}

.indicateur:dir(rtl) {
    float: left;

}

.indicateur .slide {
    display: block;
    width: 100%;
    height: 100%;

    /*border: solid 2px #fff;*/
    border-radius: 5px;
    background: linear-gradient(#af38c8 30%, #c43edf);
    ;

    box-shadow: inset 0 3px 0 0 #e8c5ee;


    inset-inline-start: 0;
}

.indicateur.achievement .slide {
    display: block;
    width: 100%;
    height: 100%;

    /*border: solid 2px #fff;*/
    border-radius: 5px;
    background: linear-gradient(#fac31a 30%, #fcd627);
    ;

    box-shadow: inset 0 3px 0 0 #f5f37a;


    inset-inline-start: 0;
}

.indicateur .percent {
    display: block;
    width: 100%;

    position: absolute;
    top: 0;
    color: #e5d796;
    text-shadow: -1px 1px 2px #77006b;
    text-align: center;
    font-weight: bolder;
}

.indicateur.achievement .percent {
    text-shadow: -1px 1px 2px #986a1c;
    color: #fcd747;
}

.indicateur .numero {
    display: block;
    width: 95%;
    text-align: end;
    font-weight: bolder;
    position: absolute;
    top: 0;
    color: #FCD747;
    text-shadow: -1px 1px 2px #a8813f;
    text-indent: -2px;

}


.indicateur .numero.vert {
    color: #76a715;
    text-shadow: -1px 1px 2px #325201;
}

.indicateur .iconCoin {
    display: block;
    position: absolute;
    top: -5px;
    inset-inline-start: -10px;
    width: 30px;
    height: 50px;
    background: center/cover no-repeat url(../../public/assets/icons/Coin.png);
    z-index: 2;
}

.indicateur .iconAchievement {
    display: block;
    position: absolute;
    top: -7px;
    inset-inline-start: -15px;
    width: 30px;
    height: 32px;
    background: center/cover no-repeat url(../../public/assets/icons/Achievement.png);
    z-index: 2;
}

.indicateur .iconLevel {
    display: block;
    position: absolute;
    top: -1px;
    inset-inline-start: -20px;
    width: 39px;
    height: 26px;
    background: center/cover no-repeat url(../../public/assets/icons/Level.png);
    z-index: 2;

    color: #e5d796;
    text-shadow: -1px 1px 2px #77006b;
    text-align: center;
    font-weight: bolder;
    line-height: 1.4em;
}


.indicateur .iconBuck {
    display: block;
    position: absolute;
    top: -12px;
    inset-inline-start: -20px;
    width: 40px;
    height: 40px;
    background: center/cover no-repeat url(../../public/assets/icons/pileOfMoney.png);
    z-index: 2;
}

.indicateur .icon-plus {
    pointer-events: auto;
    position: absolute;
    transform: scale(0.6);
    inset-inline-end: -18px;
    bottom: -30px;
    z-index: 1;
}